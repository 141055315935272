import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Group, GroupDatabasesInfo, ListOfIds, User} from "@icure/api";
import {DesktopService} from "./desktop.service";

@Injectable({
  providedIn: 'root',
})
export class GroupService{
  constructor(
    private api: ApiService,
    private desktopService: DesktopService
  ) {
  }

  public async getAllGroups(): Promise<Array<Group>>{
    return await this.api.groupApi?.listGroups()!!;
  }

  public async getGroup(groupId: string): Promise<Group>{
    return await this.api.groupApi?.getGroup(groupId)!!;
  }

  public async getGroupStorageInfos(groupId: string): Promise<GroupDatabasesInfo>{
    const groupDatabasesInfos = await this.api.groupApi?.getGroupsStorageInfos(new ListOfIds({ids: [groupId]}))!!;
    return groupDatabasesInfos?.[0];
  }

  public async getGroupsStorageInfos(groupIds: Array<string>): Promise<Array<GroupDatabasesInfo>>{
    return await this.api.groupApi?.getGroupsStorageInfos(new ListOfIds({ids: groupIds}))!!;
  }
}
