<div class="home-wrapper d-flex flex-column align-items-center" *ngIf="userInfoApi?.id">
  <div class="home-wrapper__box d-flex flex-column" *ngIf="!isLoading">
    <div class="d-flex justify-content-center">
      <img src="../../assets/img/logo-medispring-vertical.svg" width="150vw" height="150vh" />
    </div>
    <div>
      <div class="classic">
        <div class="userInfo">Bienvenue sur le cockpit {{userInfoApi?.given_name}} {{userInfoApi?.family_name}}</div>
      </div>
    </div>
    <div class="full-width mt10" *ngIf="!isValidKey">
      <mat-form-field class="full-width">
        <mat-label>Clé</mat-label>
        <input type="text" matInput [(ngModel)]="userKey">
      </mat-form-field>
      <div class="buttons">
        <button mat-flat-button color="primary" (click)="validateKey()">Suivant</button>
      </div>
    </div>
    <div class="buttons" *ngIf="accessRight?.rights?.length && isValidKey">
      <div *ngFor="let right of accessRight?.rights">
        <button mat-flat-button color="primary" class="mr10" (click)="login(right?.version)">Version {{right?.version}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading">
    <div class="textaligncenter mb10">
      <mat-progress-spinner class="spinner"></mat-progress-spinner>
    </div>
  </div>
</div>




