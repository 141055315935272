<div class="title" mat-dialog-title *ngIf="data?.action === 'add'">Sélection de la version dans laquelle le groupe sera activé</div>
<div class="title" mat-dialog-title *ngIf="data?.action === 'remove'">Sélection de la version dans laquelle le groupe sera désactivé</div>
<div class="dialog-container-content">
  <mat-radio-group [(ngModel)]="selectedVersion" class="radioGroup">
    <mat-radio-button matInput [value]="version" [checked]="version === selectedVersion" *ngFor="let version of data.availableVersions">
      {{version}}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div class="dialog-container-button">
  <button mat-button class="mr10" (click)="closeDialog()">Fermer</button>
  <button mat-button class="primary-btn" (click)="confirmVersion()">Valider</button>
</div>
