import {Injectable} from "@angular/core";
import {FetchService} from "./fetch.service";
import {UserService} from "./user.service";
import {DesktopApiCredentials} from "../models/desktop.model";
import {FetchResponse} from "./couch-db.service";
import {GroupRelease} from "../models/group.model";

@Injectable({
  providedIn: 'root',
})
export class DesktopService {

  private env_prod = 'prod';
  private env_dev = 'dev';
  private env: string = 'dev';

  constructor(
    private fetchService: FetchService,
    private userService: UserService,
  ) {
    this.env = this.env_prod;
  }

  public async listGroups(): Promise<FetchResponse>{
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/group/`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getGroupConfig(groupId: string): Promise<any> {
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/group/${groupId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getAvailableReleaseVersions(): Promise<FetchResponse>{
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/release`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getReleaseInfos(versions: string[] = ['3.2.0']): Promise<GroupRelease[]> {
    let releases: GroupRelease[] = [];
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    for(let version of versions){
      const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/release/${version}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });

      if(resp?.response?.status === 200){
        releases.push(resp?.content);
      }
    }
    return releases;
  }

  public async addGroupIdToRelease(groupId: string, releaseVersion: string): Promise<FetchResponse | undefined> {
    let response: FetchResponse | undefined;
    const releaseInfos: GroupRelease[] = await this.getReleaseInfos([releaseVersion]);
    if(releaseInfos?.length){
      const release: GroupRelease = releaseInfos[0];
      release?.groupIds.push({
        groupId: groupId,
        onlyQA: false,
        onlyDEV: false,
        onlyConfigIds: []
      });
      response = await this.postRelease(release);
    }
    return response;
  }

  public async removeGroupIdFromRelease(groupId: string, releaseVersion: string): Promise<FetchResponse | undefined> {
    let response: FetchResponse | undefined;
    const releaseInfos: GroupRelease[] = await this.getReleaseInfos([releaseVersion]);
    if(releaseInfos?.length){
      const release: GroupRelease = releaseInfos[0];
      release.groupIds = release?.groupIds?.filter((group) => group?.groupId !== groupId);
      response = await this.postRelease(release);
    }
    return response;
  }

  private async postRelease(release: GroupRelease): Promise<FetchResponse> {
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/release/`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(release)
    })

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async deleteConfigInGroup(groupId: string, configId: string):Promise<FetchResponse>{
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/group/${groupId}/config/${configId}`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getAllApplicationSettings(groupId: string, groupPassword: string, cluster: string): Promise<FetchResponse>{
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/icure-couch-cloud/application-settings?groupId=${groupId}&cluster=${cluster}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'group-pwd': groupPassword
      },
    });

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getApplicationSettingById(groupId: string, groupPassword: string, cluster: string, appSettingId: string): Promise<FetchResponse>{
    const crendentials: DesktopApiCredentials = await this.userService.getDesktopApiCredentials(this.env);
    const resp = await this.fetchService.executeFetchRequest(`${crendentials?.url}/api/v1/icure-couch-cloud/application-settings/${appSettingId}?groupId${groupId}&cluster=${cluster}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+btoa(crendentials?.login+':'+crendentials?.password),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'group-pwd': groupPassword
      },
    });

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }
}
