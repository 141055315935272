import {ApiService} from "./api.service";
import {AuthenticationResponse, User} from "@icure/api";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Credentials} from "../models/credentials.model";
import {setAuthenticationProvider} from "../authentication/authenticationProviderCache";
import {JwtAuthenticationProvider} from "@icure/api/icc-x-api/auth/AuthenticationProvider";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private api: ApiService,
    private router: Router,
  ) {
  }

  public async getCurrentUser(){
    return this.api.userApi!!.getCurrentUser().then((user: User) => {
      return user;
    });
  }

  public async login(credentials: Credentials){
    return this.api.authApi?.login(credentials).then((response: AuthenticationResponse) => {
      if (!response.successful) {
        throw new Error('wrong credentials');
      }
      setAuthenticationProvider(new JwtAuthenticationProvider(
       this.api.authApi!!,
       credentials.getFullUsername(),
       credentials.password,
       credentials.icureToken
      ));
      return this.getCurrentUser();
    });
  }

  public logout(): Promise<void> {
    return this.api.authApi!!.logoutPost().then((response: AuthenticationResponse) => {
      if (!response.successful) {
        throw new Error('logout failed');
      }
      this.router.navigate(['/']);
    });
  }
}
