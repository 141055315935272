import {Component, Input, OnInit} from '@angular/core';
import {Group, GroupDatabasesInfo} from "@icure/api";
import {getDbSize} from "../../../../../lib/utils/tools-utils";

@Component({
  selector: 'ms-database-group-info-database',
  templateUrl: './database-group-info-database.component.html',
  styleUrls: ['./database-group-info-database.component.scss']
})
export class DatabaseGroupInfoDatabaseComponent implements OnInit{

  @Input() groupStorageInfos: GroupDatabasesInfo = {};
  @Input() selectedGroup: Group = {};

  public displayedColumns: string[] = ['id', 'docNumber', 'size', 'url', 'btn'];

  constructor() {
  }

  ngOnInit() {
  }

  public createConnectionUrl(databaseId: string): string{
    const server: string = this.selectedGroup?.servers?.[0]!!;
    return `${server}/_utils/#database/${databaseId}/_all_docs`;
  }

  public redirectToFauxton(databaseId: string): void{
    window.open(
      this.createConnectionUrl(databaseId),
      '_blank' //
    );
  }

  public copyToClipboard(text: string): void{
    navigator.clipboard.writeText(text);
  }

  public getSize(size: number): string{
    return getDbSize(size)
  }

}
