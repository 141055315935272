import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {GroupService} from "../../../../lib/services/group.service";
import {UserService} from "../../../../lib/services/user.service";
import {Group} from "@icure/api";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {
  ActivityIndicatorWithMessages
} from "../../../../lib/activity-indicator-with-messages/activity-indicator-with-messages.component";
import {SelectionModel} from "@angular/cdk/collections";
import {UserInfo} from "../../../../lib/models/user.model";

export const KEYS_PROP = 'org.taktik.icure.shepherdJS.keysGenerationSteps';
export const LICENCE_KEY = 'LICENCE_KEY';
export const ASSISTANT_INFO = 'be.medispring.preferences.assistantInvoicingInformation';

@Component({
  selector: 'ms-database-user',
  templateUrl: './database-user.component.html',
  styleUrls: ['./database-user.component.scss']
})
export class DatabaseUserComponent implements OnInit, AfterViewInit{

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  // @ts-ignore

  public isLoading: boolean = false;
  public allGroups: Array<Group> = [];
  public displayedColumns: Array<string> = ['select', 'creationDateHr', 'name', 'login', 'nihii', 'speciality', 'ssin', 'street', 'postalCode', 'city', 'country', 'steps', 'actions'];
  // @ts-ignore
  public userInfos: MatTableDataSource;
  public messages: Array<ActivityIndicatorWithMessages> = [];

  // @ts-ignore
  public selection: SelectionModel;

  public blockedUser: boolean = true;
  public inactiveUser: boolean = true;
  public activeUser: boolean = true;
  public selectedFilter: string = '';

  public listOfUsers: Array<UserInfo> = [];

  constructor(
    private groupService: GroupService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initialize()
  }

  private async initialize(){
    const initialSelection: any = [];
    const allowMultiSelect = true;

    this.resetLoader();
    this.isLoading = true;
    this.messages.push({
      isDone: false,
      message: 'Récupération des groupes'
    })
    this.allGroups = await this.groupService.getAllGroups();
    this.messages.push({
      isDone: false,
      message: 'Récupération des utilisateurs'
    })
    this.listOfUsers = await this.userService.getFullUserInfos(this.allGroups);
    this.userInfos = new MatTableDataSource(this.listOfUsers);
    this.selection = new SelectionModel<MatTableDataSource<UserInfo>>(allowMultiSelect, initialSelection);
    this.isLoading = false;
    this.resetLoader();
  }

  private resetLoader(){
    this.messages = [];
  }

  ngAfterViewInit() {
    if(this.isLoading){
      setTimeout(() => {
        this.ngAfterViewInit();
      }, 1000)
    }else{
      this.userInfos.sort = this.sort;
      this.userInfos.sortingDataAccessor = (item: any, property: string) => {
        switch (property) {
          case 'creationDateHr': {
            let newDate = item.creationDate;
            return newDate;
          }
          default: {
            return item[property];
          }
        }
      };
    }
  }

  public filterChanged(){
    let filteredUser: Array<UserInfo> = []

    this.activeUser ? filteredUser = filteredUser.concat(this.listOfUsers?.filter((data: UserInfo) => data?.steps?.step_5 === true)) : null;
    this.blockedUser ? filteredUser = filteredUser.concat(this.listOfUsers?.filter((data: UserInfo) => data?.steps?.step_1 === true && data?.steps?.step_5 === false)) : null;
    this.inactiveUser ? filteredUser = filteredUser.concat(this.listOfUsers?.filter((data: UserInfo) => data?.steps?.step_1 === false)) : null;
    !this.activeUser && !this.inactiveUser && !this.blockedUser ? filteredUser = filteredUser.concat(this.listOfUsers) : null;

    this.userInfos.data = filteredUser;

    if(this.selectedFilter){
      const searchFilter = this.selectedFilter;
      this.userInfos.filter = searchFilter;
      if (searchFilter.length > 2) {
        this.userInfos.filter = searchFilter;
      }else{
        this.userInfos.filter = "";
      }
    }else{
      this.userInfos.filter = "";
    }
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userInfos.filteredData.length;
    return numSelected == numRows;
  }

  public toggleAllRows() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.userInfos.filteredData.forEach((row: any) => this.selection.select(row));
  }
}
