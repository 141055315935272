import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {GroupService} from "../../../../lib/services/group.service";
import {UserService} from "../../../../lib/services/user.service";
import {AccessLog, ApplicationSettings, Group, GroupDatabasesInfo, HealthcareParty, User} from "@icure/api";
import {HcpService} from "../../../../lib/services/hcp.service";
import {
  ActivityIndicatorWithMessages
} from "../../../../lib/activity-indicator-with-messages/activity-indicator-with-messages.component";
import {LocalSettingsService} from "../../../../lib/services/local-settings.service";
import {
  AccessMatrixService,
  ModuleTypeEnum,
  RouteTypeEnum,
  SubModuleTypeEnum,
  VersionType
} from "../../../../lib/services/access-matrix.service";
import {DesktopService} from "../../../../lib/services/desktop.service";
import {AccessLogService} from "../../../../lib/services/access-log.service";
import {ApplicationSettingsService} from "../../../../lib/services/application-settings.service";

@Component({
  selector: 'ms-database-group-info',
  templateUrl: './database-group-info.component.html',
  styleUrls: ['./database-group-info.component.scss']
})
export class DatabaseGroupInfoComponent implements OnInit{

  public groupId: string = '';
  public selectedGroup: Group = {};
  public listOfUsers: Array<User> = [];
  public groupStorageInfos: GroupDatabasesInfo = {};
  public listOfHcps: Array<HealthcareParty> = [];
  public isLoading: boolean = false;
  public messages: Array<ActivityIndicatorWithMessages> = [];
  public versionType: VersionType = VersionType.FULL;
  public desktopInfos: any;
  public accessLogsInfos: AccessLog[] = [];

  public widgets: {activeUsers: number; inactiveUsers: number; allUsers: number; agendaUsers: number;} = {
    activeUsers: 0,
    inactiveUsers: 0,
    allUsers: 0,
    agendaUsers: 0
  }

  public appSettings: ApplicationSettings[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private userService: UserService,
    private hcpService: HcpService,
    private localSettingService: LocalSettingsService,
    public accessMatrixService: AccessMatrixService,
    private desktopService: DesktopService,
    private appSettingsService: ApplicationSettingsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.groupId = param?.['groupId'];
      this.initialized()
    })
  }

  private async initialized(){
    this.messages = [];
    this.isLoading = true;
    this.versionType = this.localSettingService.version;
    this.messages.push({message: 'Récupération des infos du groupe', isDone: false});
    this.selectedGroup = await this.groupService.getGroup(this.groupId);
    this.groupStorageInfos = await this.groupService.getGroupStorageInfos(this.groupId);
    this.messages.push({message: 'Récupération des utilisateurs', isDone: false});
    this.listOfUsers = await this.userService.getUserInGroup(this.groupId);
    this.listOfHcps = await this.hcpService.getHcpsInGroup(this.groupId, this.listOfUsers?.filter(user => !user?.patientId)?.map(usr => usr?.healthcarePartyId!!));
    this.desktopInfos = this.accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_HARDWARE) ? await this.desktopService.getGroupConfig(this.groupId).catch(e => e) : null;
    this.appSettings = await this.appSettingsService?.getAllApplicationSettings(this.selectedGroup?.id!!, this.selectedGroup?.password!!, this.selectedGroup?.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', '')!!);
    this.widgets = {
      allUsers: this.listOfUsers?.filter(user => !user?.patientId)?.length,
      activeUsers: this.listOfUsers?.filter(user => user?.status === 'ACTIVE' && !user?.patientId)?.length,
      inactiveUsers: this.listOfUsers?.filter(user => user?.status !== 'ACTIVE' && !user?.patientId)?.length,
      agendaUsers: this.listOfUsers?.filter(user => user?.patientId)?.length
    }
    this.isLoading = false;
  }

  public goToGroups(){
    this.router.navigate(['/private/database/groups']);
  }

  public get VersionType(){
    return VersionType;
  }

  public get RouteTypeEnum(){
    return RouteTypeEnum;
  }

  public get ModuleTypeEnum(){
    return ModuleTypeEnum;
  }

  public get SubModuleTypeEnum(){
    return SubModuleTypeEnum;
  }

}
