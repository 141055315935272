import {
  IccAccesslogApi,
  IccAuthApi,
  IccCryptoXApi,
  IccGroupApi, IccHcpartyApi,
  IccHcpartyXApi,
  IccIcureApi, IccPatientApi,
  IccUserXApi,
  XHR
} from "@icure/api";
// @ts-ignore
import { fetch as fetchPolyfill } from 'whatwg-fetch';
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AuthenticationProvider} from "@icure/api/icc-x-api/auth/AuthenticationProvider";

export interface ErrorHandlingIccApi {
  handleError: (err: XHR.XHRError) => never;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public hcPartyApi: IccHcpartyXApi | undefined;
  public userApi: IccUserXApi | undefined;

  public authApi: IccAuthApi | undefined;
  public icureApi: IccIcureApi | undefined;
  public groupApi: IccGroupApi | undefined;
  public accessLogApi: IccAccesslogApi | undefined;
  public cryptoApi: IccCryptoXApi | undefined;

  constructor(
    private router: Router
  ) {
  }

  public reconfigure(
    host: string,
    headers: any,
    authProvider: AuthenticationProvider
  ): void {
    this.authApi = this.patchErrorHandler(new IccAuthApi(host, headers, authProvider, fetchPolyfill));
    this.hcPartyApi = this.patchErrorHandler(new IccHcpartyXApi(host, headers, authProvider, fetchPolyfill));
    this.userApi = this.patchErrorHandler(new IccUserXApi(host, headers, authProvider, fetchPolyfill));
    this.icureApi = this.patchErrorHandler(new IccIcureApi(host, headers, authProvider, fetchPolyfill));
    this.groupApi = this.patchErrorHandler(new IccGroupApi(host, headers, authProvider, fetchPolyfill));
    this.accessLogApi = this.patchErrorHandler(new IccAccesslogApi(host, headers, authProvider, fetchPolyfill));
  }

  protected patchErrorHandler<T extends ErrorHandlingIccApi>(instance: T): T {
    const that = this;
    instance['handleError'] = function handleErrorPatched(err: XHR.XHRError) {
      if (err.statusCode === 401) {
        if (!['/'].includes(that.router.url.split('?')[0])) {
          that.router.navigate(['/']);
        }
      }
      throw err;
    };
    return instance;
  }
}


