<h5 mat-dialog-title>Ajout d'un utilisteur dans la db</h5>

<div class="form-container">
    <div class="table">
        <div class="tr">
            <div class="td w50">
                <mat-form-field appearance="fill">
                    <mat-label>Nom</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.lastName" required>
                </mat-form-field>
            </div>
            <div class="td w50">
                <mat-form-field appearance="fill">
                    <mat-label>Prénom</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.firstName" required>
                </mat-form-field>
            </div>
        </div>
        <div class="tr">
            <div class="td w100">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.email" required>
                </mat-form-field>
            </div>
        </div>
        <div class="tr">
            <div class="td w50">
                <mat-form-field appearance="fill">
                    <mat-label>Niss</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.ssin">
                </mat-form-field>
            </div>
            <div class="td w50">
                <mat-form-field appearance="fill">
                    <mat-label>Inami</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.nihii">
                </mat-form-field>
            </div>
        </div>
        <div class="tr">
            <div class="td w100">
                <mat-form-field appearance="fill">
                    <mat-label>Mot de passe</mat-label>
                    <input matInput [(ngModel)]="newUserInfos.password" type="password">
                </mat-form-field>
            </div>
        </div>
      <div class="tr">
        <div class="td w100">
          <mat-form-field appearance="fill">
            <mat-label>Mot de passe temporaire</mat-label>
            <input matInput [(ngModel)]="newUserInfos.passwordTemp" type="passwordTemp">
          </mat-form-field>
        </div>
      </div>
      <div class="tr">
        <div class="td w100">
          <mat-label>Rôles</mat-label>
          <mat-checkbox *ngFor="let role of availableRoles" (change)="roleChanged($event)" [value]="role.id" [checked]="role.defaultChecked">{{role.description}}</mat-checkbox>
        </div>
      </div>
        <div class="tr">
            <div class="td w100">
                <mat-form-field appearance="fill">
                    <mat-label>ParentId</mat-label>
                    <mat-select [(ngModel)]="newUserInfos.parentId">
                        <mat-option value="" aria-selected="true">Aucun</mat-option>
                        <mat-option *ngFor="let parentId of availableParentId" value="{{parentId?.hcpId}}">{{parentId?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
      <div class="buttons-container">
        <button mat-flat-button class="m5" color="primary" type="button" (click)="createUser()">Créer</button>
        <button mat-raised-button class="m5" color="warn" type="button" (click)="closeDialog()">Annuler</button>
      </div>
</div>
