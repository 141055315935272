import {Injectable} from "@angular/core";
import {FetchService} from "./fetch.service";
import {FetchResponse} from "./couch-db.service";
import * as msConfig from "../../../msconfig.json";

const ENDPOINT_OAUTH_API = 'https://accounts.google.com/o/oauth2/v2/auth';
const ENDPOINT_JSON_API = 'https://storage.googleapis.com/storage/v1';
const ENDPOINT_REVOKE_API = 'https://oauth2.googleapis.com/revoke';


@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {

  private env: string = 'prod';

  constructor(
    private fetchService: FetchService
  ) {
  }

  public getOAuthAuthorizationUrl(): string{
    const url = `${ENDPOINT_OAUTH_API}?scope=${msConfig?.google_api?.prod?.oAuth?.scopes?.join(' ')}&include_granted_scopes=true&response_type=token&state=${msConfig?.google_api?.prod?.oAuth?.state}&redirect_uri=${msConfig?.google_api?.prod?.oAuth?.redirectUri}&client_id=${msConfig?.google_api?.prod?.clientId}`;
    return url;
  }

  public async getUserInfos(token: string): Promise<FetchResponse> {
    const resp = await this.fetchService.executeFetchRequest(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${token}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async revokeOAuthToken(token: string){
    const resp = await this.fetchService.executeFetchRequest(`${ENDPOINT_REVOKE_API}?token=${token}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async getFileFromStorage(token: string): Promise<FetchResponse>{
    const resp = await this.fetchService.executeFetchRequest(`${ENDPOINT_JSON_API}/b/${msConfig?.google_api?.prod?.bucket_rights?.name}/o/${msConfig?.google_api?.prod?.bucket_rights?.fileName}?alt=media`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    })

    return {
      status: {
        status: resp?.response?.status,
        statusText: resp?.response?.statusText,
      },
      content: resp?.content
    };
  }
}

