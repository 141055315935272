import {Component, OnInit, ViewChild} from '@angular/core';
import {GroupService} from "../../../../lib/services/group.service";
import {AvailableRelease, GroupInfo, GroupRelease, GroupReleaseInfos} from "../../../../lib/models/group.model";
import {CouchDbService, DbTypeEnum, FetchResponse} from "../../../../lib/services/couch-db.service";
import {
  ActivityIndicatorWithMessages,
} from "../../../../lib/activity-indicator-with-messages/activity-indicator-with-messages.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {LocalSettingsService} from "../../../../lib/services/local-settings.service";
import {DesktopService} from "../../../../lib/services/desktop.service";
import {
  AccessMatrixService,
  ModuleTypeEnum,
  RouteTypeEnum,
  SubModuleTypeEnum,
  VersionType
} from "../../../../lib/services/access-matrix.service";
import {Group, sleep} from "@icure/api";
import {WarmupService} from "../../../../lib/services/warmup.service";
import {ApiService} from "../../../../lib/services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  HardwareInfoDialogComponent
} from "../database-group-info/dialogs/hardware-info-dialog/hardware-info-dialog.component";
import {ReleaseSelectorDialogComponent} from "../dialogs/release-selector-dialog/release-selector-dialog.component";

export const SHARED_DB = 'ms-medispring-free-reference-8227c0f3-6e6f-401d-8e8a-a94b199636be';
export const FREE_DB = 'ms-free-super-admin'
export const CL1 = 'couch-cluster-01'
export const CL2 = 'couch-cluster-02'

export enum RolloutTypeEnum{
  STAGED = 'staged',
  ALL = 'all'
}

export enum UpdateTypeEnum{
  ALLOWED = 'allowed',
  PARTIALLY_ALLOWED = 'partiallyAllowed',
  NOT_ALLOWED = 'notAllowed'
}

export enum MessageTypeEnum{
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning"
}

export enum ReleaseActionEnum{
  ADD = 'add',
  REMOVE = 'remove'
}

@Component({
  selector: 'ms-database-group',
  templateUrl: './database-group.component.html',
  styleUrls: ['./database-group.component.scss']
})
export class DatabaseGroupComponent implements OnInit{

  // @ts-ignore
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ts-ignore
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private allGroups: Array<any> = [];
  private allGroupsReleaseInfos: GroupRelease[] = [];
  private availableReleaseVersions: any;
  // @ts-ignore
  public groupInfosDataSource: MatTableDataSource;
  public groupInfos: Array<GroupInfo> = [];
  public freeColumns: string[] = ['name', 'groupId', 'servers', 'actions'];
  public fullColumns: string[] = ['name', 'groupId', 'servers', 'active', 'version', 'authorizedVersion', 'actions'];
  public displayedColumns: string[] = [];
  public isLoading: boolean = false;
  public isWarmUp: boolean = false;
  public messages: Array<ActivityIndicatorWithMessages> = [];
  public warmUpMessages: Array<ActivityIndicatorWithMessages> = [];
  public versionType: VersionType = VersionType.FULL;
  public selectedGroupId: string = '';

  public krakenUser: boolean = false;
  public selectedFilter: string = '';
  public activeGroups: Array<string> = [];

  constructor(
    private groupService: GroupService,
    private couchDbService: CouchDbService,
    private userListDialog: MatDialog,
    private localSettingsService: LocalSettingsService,
    private desktopService: DesktopService,
    public accessMatrixService: AccessMatrixService,
    private warmUpService: WarmupService,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private releaseSelectorDialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.versionType = this.localSettingsService.version;
    this.displayedColumns = this.versionType === VersionType.FREE ? this.freeColumns : this.fullColumns;
    this.initialize();
  }

  private async initialize(): Promise<void>{
    this.messages = [];
    this.isLoading = true;
    this.groupInfosDataSource = new MatTableDataSource();
    this.allGroups = [];
    this.groupInfos = [];

    this.messages.push({message: 'Récupération des groupes', isDone: false});
    this.allGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();

    if(this.accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_DESKTOP_API)){
      const activeGroupsResp = await this.desktopService.listGroups();
      this.activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const getAvailableReleaseVersionsResponse: FetchResponse = await this.desktopService?.getAvailableReleaseVersions();
      this.availableReleaseVersions = getAvailableReleaseVersionsResponse?.content;
      this.allGroupsReleaseInfos = await this.desktopService.getReleaseInfos(this.availableReleaseVersions?.filter((version: any) => version?.startsWith('3')));
    }

    this.allGroups?.map(group => {
      const availableReleases: AvailableRelease[] = this.getUpdateAuthorization(group);
        this.groupInfos.push({
        groupId: group?.id,
        groupPassword: group?.password,
        name: group?.name,
        superGroup: group?.superGroup === FREE_DB ? 'Free' : 'Full',
        sharedEntities: {
          code: this.checkSharedEntity(group?.sharedEntities?.Code),
          insurance: this.checkSharedEntity(group?.sharedEntities?.Insurance),
          tarification: this.checkSharedEntity(group?.sharedEntities?.Tarification),
        },
        serversHr: this.checkCluster(group?.servers),
        servers: group?.servers,
        groupRaw: group,
        releaseVersion: availableReleases,
        isActive: this.activeGroups?.includes(group?.id),
        isKrakenVersion: !!availableReleases?.find(ar => ar?.authorization === UpdateTypeEnum.ALLOWED || ar?.authorization === UpdateTypeEnum.PARTIALLY_ALLOWED)
      })
    })
    this.groupInfosDataSource = new MatTableDataSource(this.groupInfos);
    this.initializeGridOptions();
    this.isLoading = false;
  }

  private getUpdateAuthorization(group: Group){
    return this.allGroupsReleaseInfos?.map((groupRelease: GroupRelease) => {
      const gri: GroupReleaseInfos | null = groupRelease?.groupIds?.find(gri => gri?.groupId === group?.id) || null;
      let authorization: UpdateTypeEnum = UpdateTypeEnum.NOT_ALLOWED;
      if(gri){
        if(groupRelease?.rolloutType === RolloutTypeEnum?.STAGED){
          authorization = gri?.onlyDEV === false && gri?.onlyQA === false && gri?.onlyConfigIds?.length === 0 ? UpdateTypeEnum.ALLOWED : (gri?.onlyDEV !== false || gri?.onlyQA !== false || gri?.onlyConfigIds?.length !== 0) ? UpdateTypeEnum.PARTIALLY_ALLOWED : UpdateTypeEnum.NOT_ALLOWED
        }else if(groupRelease?.rolloutType === RolloutTypeEnum?.ALL){
          authorization = UpdateTypeEnum.ALLOWED;
        }
      }
      return {
        version: groupRelease?.version,
        authorization: authorization
      }
    })?.filter(x => !!x);
  }

  private initializeGridOptions(){
    //Use this to reinitialize sorting
    this.sort.sort({id: '', start: 'desc'} as MatSortable)
    this.sort.sort({id: 'name', start: 'asc'} as MatSortable)
    this.groupInfosDataSource.sort = this.sort;
    this.groupInfosDataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'creationDateHr': {
          let newDate = item.creationDate;
          return newDate;
        }
        default: {
          return item[property];
        }
      }
    };
    this.groupInfosDataSource.paginator = this.paginator;
  }

  private checkSharedEntity(entity: string): boolean{
    return entity?.includes(SHARED_DB) || false;
  }

  private checkCluster(servers: Array<string>): string{
    return servers?.map(ser => ser.includes(CL1) ? 'Cluster 01' : 'Cluster 02').join(',');
  }

  public async connectAndRedirect(groupInfo: GroupInfo){
    const session: FetchResponse = await this.couchDbService.createSession(groupInfo?.groupId!!, groupInfo?.groupPassword!!, groupInfo?.servers[0]);
    if(session?.status?.status === 200){
      const connection = await this.couchDbService.connectToDb(groupInfo?.groupId!!, groupInfo?.groupPassword!!, groupInfo?.servers[0], DbTypeEnum.BASE);
    }
  }

  filterChanged(){
    let filteredGroup: Array<GroupInfo> = []
    filteredGroup = this.krakenUser ? this.groupInfos?.filter((data: GroupInfo) => data?.isKrakenVersion === true) : this.groupInfos;

    this.groupInfosDataSource.data = filteredGroup;

    if(this.selectedFilter) {
      const searchFilter = this.selectedFilter;
      this.groupInfosDataSource.filter = searchFilter;
      if (searchFilter.length > 2) {
        this.groupInfosDataSource.filter = searchFilter;
      } else {
        this.groupInfosDataSource.filter = "";
      }
    }else{
      this.groupInfosDataSource.filter = "";
    }
  }

  public get RouteTypeEnum(){
    return RouteTypeEnum;
  }

  public get ModuleTypeEnum(){
    return ModuleTypeEnum;
  }

  public get SubModuleTypeEnum(){
    return SubModuleTypeEnum;
  }

  get UpdateTypeEnum(){
    return UpdateTypeEnum;
  }

  public async activeWarmUp(groupInfo: GroupInfo){
    if(groupInfo?.groupId && groupInfo?.servers){
      let index = 0;
      this.isWarmUp = true;
      this.selectedGroupId = groupInfo?.groupId;
      this.warmUpMessages = [];
      for(let entity of this.warmUpService.getEntitiesToWarmUp()){
       this.warmUpMessages.push({id: index.toString(), message: `Vue: ${entity.entity}`, isDone: false});
        const result = await this.warmUpService.updateDesignDoc(entity.entity, groupInfo?.groupId, groupInfo?.servers);
        if (result?.status?.status === 200) {
          this.warmUpMessages.find(m => m.id === index.toString())!!.isDone = true;
        }else{
          console.log(`Error while updating design doc for entity ${entity.entity} : ${result?.status?.statusText}`);
        }
        index ++;
      }
      this.isWarmUp = false;
    }
  }

  public openVersionSelector(groupInfo: GroupInfo, action: ReleaseActionEnum){
    const availableVersions = action === ReleaseActionEnum.ADD ? this.availableReleaseVersions : groupInfo?.releaseVersion?.map(v => v?.version);
    const dialogRef = this.releaseSelectorDialog.open(ReleaseSelectorDialogComponent, {
      data: {
        availableVersions: availableVersions,
        action: action
      },
      height: '400px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(version => {
      if(version){
        if(action === ReleaseActionEnum.ADD){
          this.authorizeGroupInRelease(groupInfo, version);
        }else if(action === ReleaseActionEnum.REMOVE){
          this.removeGroupFromRelease(groupInfo, version);
        }
      }
    });
  }

  public async authorizeGroupInRelease(groupInfo: GroupInfo, version: string){
    this.isLoading = true;
    const resp: FetchResponse | undefined = await this.desktopService.addGroupIdToRelease(groupInfo?.groupId!!, version);
    if(resp?.status?.status === 200){
      this.displayWarningMessage(`Le groupId: ${groupInfo?.groupId} à bien été activé dans la release`, MessageTypeEnum.SUCCESS);
      await this.initialize();
    }else{
      this.displayWarningMessage(`Erreur lors de l'activation du groupId: ${groupInfo?.groupId}`, MessageTypeEnum.ERROR);
    }
    this.isLoading = false;
  }

  public async removeGroupFromRelease(groupInfo: GroupInfo, version: string){
    this.isLoading = true;
    const resp: FetchResponse | undefined = await this.desktopService.removeGroupIdFromRelease(groupInfo?.groupId!!, version);
    if(resp?.status?.status === 200){
      this.displayWarningMessage(`Le groupId: ${groupInfo?.groupId} à bien été supprimé de la release`, MessageTypeEnum.SUCCESS);
      await this.initialize();
    }else{
      this.displayWarningMessage(`Erreur lors de la suppression du groupId: ${groupInfo?.groupId}`, MessageTypeEnum.ERROR);
    }
    this.isLoading = false;
  }

  private displayWarningMessage(message: string, messageType: MessageTypeEnum){
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [messageType]
    });
  }

  public get releaseActionEnum(){
    return ReleaseActionEnum;
  }
}
