import {Component, Input, OnInit} from '@angular/core';
import {UserInfo} from "../../../lib/models/user.model";
import {AccessMatrixService, ModuleTypeEnum, RouteTypeEnum} from "../../../lib/services/access-matrix.service";

@Component({
  selector: 'ms-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit{

  @Input() userInfos: UserInfo[] = [];

  menuElements: Array<{id: string; routerLink: string; title: string; icon: string; state?: UserInfo[], children?: Array<any>}> = []

  constructor(
    private accessMatrixService: AccessMatrixService
  ) {
  }

  ngOnInit() {
    const isDashboardAvailable: boolean = this.accessMatrixService.isAvailableModule(RouteTypeEnum.DASHBOARD);
    const isDatabaseAvailable: boolean = this.accessMatrixService.isAvailableModule(RouteTypeEnum.DATABASE);
    const isDatabaseUsersAvailable: boolean = this.accessMatrixService.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_USER);
    const isDatabaseGroupsAvailable: boolean = this.accessMatrixService.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP);
    const isReportingAvailable: boolean = this.accessMatrixService.isAvailableModule(RouteTypeEnum.REPORTING);

    const childrenOfDatabase = [];
    isDatabaseGroupsAvailable ? childrenOfDatabase.push({
      id: 'groups',
      icon: 'hub',
      title: 'Groupes',
      routerLink: './database/groups',
      state: this.userInfos
    }) : null;
    isDatabaseUsersAvailable ? childrenOfDatabase.push(
      {
        id: 'users',
        icon: 'groups',
        title: 'Utilisateurs',
        routerLink: './database/users',
        state: this.userInfos
      }
    ) : null;

    isDashboardAvailable ?
      this.menuElements.push({
          id: 'dashboard',
          routerLink: './dashboard',
          title: 'Tableau de bord',
          icon: 'dashboard',
          state: this.userInfos,
        }) : null;

    isDatabaseAvailable ? this.menuElements.push({
        id: 'database',
        routerLink: './database',
        title: 'Base de données',
        icon: 'cloud',
        children: childrenOfDatabase
      }) : null;

    isReportingAvailable ? this.menuElements.push({
      id: 'reporting',
      routerLink: './reporting',
      title: 'Reporting',
      icon: 'insert_chart_outlined'
    }) : null;
  }
}
