import {LocalSettingsService} from "./local-settings.service";
import {Injectable} from "@angular/core";

export enum RouteTypeEnum{
  DASHBOARD = 'dashboard',
  DATABASE = 'database',
  REPORTING = 'reporting',
}

export enum ModuleTypeEnum{
  DASHBOARD_WIDGET = 'dashboard_widget',
  DATABASE_GROUP = 'database_group',
  DATABASE_DESKTOP_API = "group_desktop_api",
  DATABASE_USER = 'database_user'
}

export enum SubModuleTypeEnum{
  DASHBOARD_WIDGET_ALL_DB = "all_bd",
  DASHBOARD_WIDGET_NEW_USER = "new_user",
  DASHBOARD_WIDGET_CLUSTER_1 = "cluster_1",
  DASHBOARD_WIDGET_CLUSTER_2 = "cluster_2",
  DASHBOARD_WIDGET_ALL_ACTIVE_DB = "all_active_db",
  DASHBOARD_WIDGET_DUPLICATE = "duplicate",
  DASHBOARD_WIDGET_USER_NEVER_CONNECTED = "user_never_connected",
  DASHBOARD_WIDGET_USER_PASSWORD_STEP = "user_password_step",
  DASHBOARD_WIDGET_USER_HCP_STEP = "user_hcp_step",
  DASHBOARD_WIDGET_USER_ADR_STEP = "user_adr_step",
  DASHBOARD_WIDGET_USER_INVOICE_STEP = "user_invoice_step",
  DASHBOARD_WIDGET_USER_FULL_STEP = "user_full_step",
  DASHBOARD_WIDGET_USER_WALLONIA = "user_wallonia",
  DASHBOARD_WIDGET_USER_BRUSSELS = "user_brussels",
  DASHBOARD_WIDGET_USER_FLANDERS = "user_flanders",
  DATABASE_GROUP_INFO = "group_info",
  DATABASE_GROUP_INFO_USER = "group_info_user",
  DATABASE_GROUP_INFO_USER_DIARY = "group_info_user_diary",
  DATABASE_GROUP_INFO_HARDWARE = "group_info_hardware",
  DATABASE_GROUP_INFO_SOFTWARE = "group_info_software",
  DATABASE_GROUP_INFO_DATABASE = "group_info_database",
}

export enum VersionType{
  FULL = 'full',
  FREE = 'free'
}

export interface SubModule {
  id: SubModuleTypeEnum;
  availability: VersionType[];
  options?: object;
}
export interface Module {
  id: ModuleTypeEnum;
  availability: VersionType[];
  subModules?: SubModule[];
}
export interface Route {
  id: RouteTypeEnum;
  availability: VersionType[];
  modules: Module[];
}

@Injectable({
  providedIn: 'root',
})
export class AccessMatrixService {

  public currentVersion: VersionType = VersionType.FREE;

  constructor(
    private localSettingsService: LocalSettingsService
  ) {}

  private authorizationMatrix: Route [] = [
    {
      id: RouteTypeEnum.DASHBOARD,
      availability: [VersionType.FREE, VersionType.FULL],
      modules: [
        {
          id: ModuleTypeEnum.DASHBOARD_WIDGET,
          availability: [VersionType.FREE, VersionType.FULL],
          subModules: [
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_ALL_DB,
              availability: [VersionType.FREE, VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_ALL_ACTIVE_DB,
              availability: [VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_NEW_USER,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_CLUSTER_1,
              availability: [VersionType.FREE, VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_CLUSTER_2,
              availability: [VersionType.FREE, VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_DUPLICATE,
              availability: [VersionType.FREE, VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_NEVER_CONNECTED,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_PASSWORD_STEP,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_HCP_STEP,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_ADR_STEP,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_INVOICE_STEP,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_FULL_STEP,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_WALLONIA,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_BRUSSELS,
              availability: [VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DASHBOARD_WIDGET_USER_FLANDERS,
              availability: [VersionType.FREE]
            }
          ]
        }
      ]
    },
    {
      id: RouteTypeEnum.DATABASE,
      availability: [VersionType.FREE, VersionType.FULL],
      modules: [
        {
          id: ModuleTypeEnum.DATABASE_USER,
          availability: [VersionType.FREE]
        },
        {
          id: ModuleTypeEnum.DATABASE_DESKTOP_API,
          availability: [VersionType.FULL]
        },
        {
          id:ModuleTypeEnum.DATABASE_GROUP,
          availability: [VersionType.FREE, VersionType.FULL],
          subModules: [
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO,
              availability: [VersionType.FULL, VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO_HARDWARE,
              availability: [VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO_SOFTWARE,
              availability: [VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO_USER,
              availability: [VersionType.FULL, VersionType.FREE]
            },
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO_USER_DIARY,
              availability: [VersionType.FULL]
            },
            {
              id: SubModuleTypeEnum.DATABASE_GROUP_INFO_DATABASE,
              availability: [VersionType.FULL, VersionType.FREE]
            }
          ]
        }
      ]
    },
    {
      id: RouteTypeEnum.REPORTING,
      availability: [VersionType.FULL],
      modules: []
    }
  ]

  /**
   * Check in the matrix if the route / module / submodule is available for the current version of Medispring
   * @input route: Route
   * @input module?: Module
   * @input subModule?: SubModule
   * @output boolean
   * **/
  public isAvailableModule(route: RouteTypeEnum, module?: ModuleTypeEnum, subModule?: SubModuleTypeEnum): boolean {
    const selectedRoute: Route = this.authorizationMatrix?.find(r => r.id === route)!;
    const selectedModule: Module = module ? selectedRoute?.modules?.find(m => m.id === module)! : null!;
    const selectedSubModule: SubModule = subModule ? selectedModule?.subModules?.find(s => s.id === subModule)! : null!;

    if (!!route && !!module && !!subModule) {
      return this.isAvailableRoute(selectedSubModule);
    } else if (!!route && !!module) {
      return this.isAvailableRoute(selectedModule);
    } else if (route) {
      return this.isAvailableRoute(selectedRoute);
    } else {
      return true;
    }
  }

  private isAvailableRoute(selectedRoute: SubModule | Module | Route): boolean {
    return selectedRoute?.availability?.some(
      mode => mode === this.localSettingsService.version
    );
  }

}
