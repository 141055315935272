<mat-grid-list class="mg-top-10" cols="6" rowHeight="4:1">
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_ALL_DB)">
    <div id="widget-all-db" class="dashboard-bloc">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">cloud</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.numberOfDb}}
          </div>
        </div>
        <div class="bloc-bottom-title-container">
          Nombre total de db
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_ALL_ACTIVE_DB)">
    <div id="widget-all-active-db" class="dashboard-bloc">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">cloud</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.numberOfActiveDb}}
          </div>
        </div>
        <div class="bloc-bottom-title-container">
          Nombre total de db actives
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_NEW_USER)">
    <div id="widget-last-24" class="dashboard-bloc">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">new_releases</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.newUserLast24h}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Nouveaux utilisateurs depuis 24h
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_CLUSTER_1)">
    <div id="widget-cluster_1" class="dashboard-bloc">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">spoke</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.cluster_1}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Cluster 1
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_CLUSTER_2)">
    <div id="widget-cluster_2" class="dashboard-bloc">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">spoke</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.cluster_2}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Cluster 2
        </div>
      </div>
    </div>
  </mat-grid-tile>

  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_DUPLICATE)">
    <div id="widget-double" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">railway_alert</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.duplicate}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Doublons potentiels
        </div>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list class="mg-top-10" cols="6" rowHeight="4:1">
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_NEVER_CONNECTED)">
    <div id="widget-step_1" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">supervised_user_circle</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_1}}
          </div>
        </div>
        <div class="bloc-bottom-title-container">
          Usr jamais connectés
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_PASSWORD_STEP)">
    <div id="widget-step_2" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">password</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_2}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Usr stoppés après config mdp
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_HCP_STEP)">
    <div id="widget-step_3" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">man</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_3}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Usr stoppés après config hcp
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_ADR_STEP)">
    <div id="widget-step_4" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">house</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_4}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Usr stoppés après config adr
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_INVOICE_STEP)">
    <div id="widget-step_5" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">euro</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_5}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Usr stoppés après config facturation
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_FULL_STEP)">
    <div id="widget-step_6" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">celebration</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.steps?.step_6}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Usr totalement configurés
        </div>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<mat-grid-list class="mg-top-10" cols="6" rowHeight="4:1">
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_WALLONIA)">
    <div id="widget-wal" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">map</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.districtRepartition?.wal}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Wallonie
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_BRUSSELS)">
    <div id="widget-bxl" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">map</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.districtRepartition?.bxl}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Bruxelles
        </div>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DASHBOARD, ModuleTypeEnum.DASHBOARD_WIDGET, SubModuleTypeEnum.DASHBOARD_WIDGET_USER_FLANDERS)">
    <div id="widget-fla" class="dashboard-bloc widget-steps">
      <div class="dashboard-bloc-content">
        <div class="bloc-container">
          <div class="bloc-container-icon">
            <mat-icon class="dashboard-icon">map</mat-icon>
          </div>
          <div class="bloc-container-number">
            {{widgets.districtRepartition?.fla}}
          </div>

        </div>
        <div class="bloc-bottom-title-container">
          Flandre
        </div>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<ms-activity-indicator-with-messages [fullWidth]="true" [isLoading]="isLoading" [overlay]="true" [messages]="messages"></ms-activity-indicator-with-messages>
