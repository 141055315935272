<div *ngIf="isLoading"
     [ngClass]="{
        'full-width': fullWidth,
        overlayBackground: overlay
    }">
  <div class="inner">
    <div class="textaligncenter mb10">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    <p *ngFor="let item of messages">
            <span
              class="fas ic-status text-center"
              [ngClass]="item.isDone ? 'fa-check-circle green' : 'fa-arrow-right'"
            ></span>
      {{ item.message }}
    </p>
  </div>
</div>
