export function humanReadableBytes(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "0 Bytes";
  }

  let i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return bytes + " " + sizes[i];
  }

  if (i > (sizes.length - 1)) {
    i = sizes.length - 1;
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export function getRowsUsingPagination<T>(paginator: any, maxPages: number = 0): Promise<T>{
  let currentPageNumber: number = 0;
  const executePaginator: any = async (latestResult: any, acc: any) => {
    const newResult = await paginator(latestResult.nextKey, latestResult.nextDocId);
    acc.push(...newResult.rows);
    Object.assign(newResult, {
      done: !newResult.nextKeyPair,
      nextKey:
          newResult.nextKeyPair &&
          (typeof newResult.nextKeyPair.startKey === 'string'
              ? newResult.nextKeyPair.startKey
              : JSON.stringify(newResult.nextKeyPair.startKey)),
      nextDocId: newResult.nextKeyPair && newResult.nextKeyPair.startKeyDocId,
    });
    currentPageNumber++;
    return newResult.done || (maxPages > 0 && currentPageNumber >= maxPages) ? acc : executePaginator(newResult, acc);
  };
  return executePaginator({ nextKey: null, nextDocId: null }, []);
}
