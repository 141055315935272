import { NgModule } from '@angular/core';
import {RouterModule, Routes, UrlSegment} from "@angular/router";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DatabaseComponent} from "./database/database.component";
import {DatabaseGroupInfoComponent} from "./database/database-group-info/database-group-info.component";
import {ReportingComponent} from "./reporting/reporting.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'reporting',
    component: ReportingComponent,
  },
  {
    path: 'database/:node',
    component: DatabaseComponent,
  },
  {
    matcher: url => {
      if (url?.length === 4 && url?.[2]?.path === 'group-infos') {
        return {
          consumed: url,
          posParams: {
            groupId: new UrlSegment(url?.[3]?.path, {}),
          },
        };
      }
      return null;
    }
    ,
    component: DatabaseGroupInfoComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
