<div id="database-user-container">
  <div *ngIf="!isLoading">
    <mat-toolbar id="searchBar">
      <div class="searchInput">
        <mat-form-field class="full-width">
          <mat-label>Recherche</mat-label>
          <input matInput [(ngModel)]="selectedFilter" (keyup)="filterChanged()">
        </mat-form-field>
      </div>
      <div class="extraInput">
        <section>
          <mat-checkbox labelPosition="after" [(ngModel)]="activeUser" (click)="filterChanged()">
            Actif
          </mat-checkbox>
          <mat-checkbox labelPosition="after" [(ngModel)]="inactiveUser" (click)="filterChanged()">
            Inactif
          </mat-checkbox>
          <mat-checkbox labelPosition="after" [(ngModel)]="blockedUser" (click)="filterChanged()">
            Bloqué
          </mat-checkbox>
        </section>
      </div>
    </mat-toolbar>
      <table mat-table matSort [dataSource]="userInfos">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="creationDateHr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de création</th>
          <td mat-cell *matCellDef="let element">{{element.creationDateHr}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="groupId">
          <th mat-header-cell *matHeaderCellDef>Group Id</th>
          <td mat-cell *matCellDef="let element">{{element.groupId}}</td>
        </ng-container>
        <ng-container matColumnDef="login">
          <th mat-header-cell *matHeaderCellDef>Login</th>
          <td mat-cell *matCellDef="let element">{{element.login}}</td>
        </ng-container>
        <ng-container matColumnDef="nihii">
          <th mat-header-cell *matHeaderCellDef>Inami</th>
          <td mat-cell *matCellDef="let element">{{element.nihii}}</td>
        </ng-container>
        <ng-container matColumnDef="speciality">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Spécialité</th>
          <td mat-cell *matCellDef="let element">{{element.speciality}}</td>
        </ng-container>
        <ng-container matColumnDef="ssin">
          <th mat-header-cell *matHeaderCellDef>Niss</th>
          <td mat-cell *matCellDef="let element">{{element.ssin}}</td>
        </ng-container>
        <ng-container matColumnDef="street">
          <th mat-header-cell *matHeaderCellDef>Rue</th>
          <td mat-cell *matCellDef="let element">{{element.adr.street}} {{element.adr.houseNumber}}</td>
        </ng-container>
        <ng-container matColumnDef="postalCode">
          <th mat-header-cell *matHeaderCellDef>Code postal</th>
          <td mat-cell *matCellDef="let element">{{element.adr?.postalCode}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>Ville</th>
          <td mat-cell *matCellDef="let element">{{element.adr?.city}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Pays</th>
          <td mat-cell *matCellDef="let element">{{element.adr.country}}</td>
        </ng-container>
        <ng-container matColumnDef="steps">
          <th mat-header-cell *matHeaderCellDef>Etapes</th>
          <td mat-cell *matCellDef="let element">
            <div class="steps">
              <div id="step_1" title="Définition du mdp">
                <mat-icon *ngIf="element?.steps?.step_1 === true" class="table-icon green">check_circle</mat-icon>
                <mat-icon *ngIf="element?.steps?.step_1 === false" class="table-icon red">cancel</mat-icon>
              </div>
              <div id="step_2" title="Données du hcp">
                <mat-icon *ngIf="element?.steps?.step_2 === true" class="table-icon green">check_circle</mat-icon>
                <mat-icon *ngIf="element?.steps?.step_2 === false" class="table-icon red">cancel</mat-icon>
              </div>
              <div id="step_3" title="Adresse">
                <mat-icon *ngIf="element?.steps?.step_3 === true" class="table-icon green">check_circle</mat-icon>
                <mat-icon *ngIf="element?.steps?.step_3 === false" class="table-icon red">cancel</mat-icon>
              </div>
              <div id="step_4" title="Données de facturation">
                <mat-icon *ngIf="element?.steps?.step_4 === true" class="table-icon green">check_circle</mat-icon>
                <mat-icon *ngIf="element?.steps?.step_4 === false" class="table-icon red">cancel</mat-icon>
              </div>
              <div id="step_5" title="Génération de la clé">
                <mat-icon *ngIf="element?.steps?.step_5 === true" class="table-icon green">check_circle</mat-icon>
                <mat-icon *ngIf="element?.steps?.step_5 === false" class="table-icon red">cancel</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <mat-icon>alternate_email</mat-icon>
                <span>Contacter</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  <ms-activity-indicator-with-messages [fullWidth]="true" [isLoading]="isLoading" [overlay]="true" [messages]="messages"></ms-activity-indicator-with-messages>
</div>
