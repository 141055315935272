import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../lib/services/authentication.service";
import {ApiService} from "../../../lib/services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalSettingsService} from "../../../lib/services/local-settings.service";
import {GoogleApiService} from "../../../lib/services/google-api.service";
import {AccessRight, Key, OAuthToken, UserInfoApi} from "../../../lib/models/oAuth.model";
import {User} from "@icure/api";
import {VersionType} from "../../../lib/services/access-matrix.service";
import {CryptoService} from "../../../lib/services/crypto.service";
import {LoginService} from "../../../lib/services/login.service";
import {HEADER, HOST} from "../login/login.component";
import {Credentials} from "../../../lib/models/credentials.model";
import {getAuthenticationProvider} from "../../../lib/authentication/authenticationProviderCache";

@Component({
  selector: 'ms-o-auth-login',
  templateUrl: './o-auth-login.component.html',
  styleUrls: ['./o-auth-login.component.scss']
})

export class OAuthLoginComponent implements OnInit{

    public oAuthToken: OAuthToken | null = null;
    public userInfoApi: UserInfoApi | null = null;
    public accessRight: AccessRight | null = null;
    public keys: Key[] = [];
    public isLoading: boolean = false;
    public userKey: string = '';
    public isValidKey: boolean = false;

    constructor(
      private auth: AuthenticationService,
      private api: ApiService,
      private router: Router,
      private localSettings: LocalSettingsService,
      private googleApiService: GoogleApiService,
      private route: ActivatedRoute,
      private cryptoService: CryptoService,
      private loginService: LoginService
    ) {
    }

    ngOnInit(): void {
      this.api.reconfigure(HOST, HEADER, getAuthenticationProvider());
      this.route.fragment.subscribe(fragments => {
        this.isLoading = true;
        const urlParams = new URLSearchParams(fragments!!);
        if(urlParams.get('access_token')){
          this.oAuthToken = {
            token_type: urlParams.get('token_type')!!,
            access_token: urlParams.get('access_token')!!,
            scope: urlParams.get('scope')!!,
            expires_in: urlParams.get('expires_in')!!,
            authUser: urlParams.get('authuser')!!,
            hd: urlParams.get('hd')!!,
            state: urlParams.get('state')!!
          }
          this.initialized();
        }else{
          this.isLoading = false;
          this.router.navigate(['login']);
        }
      })
    }

    private async initialized() {
      this.isValidKey = false;
      const userInfosResponse = await this.googleApiService.getUserInfos(this.oAuthToken?.access_token!!);
      if(userInfosResponse?.status?.status === 200 && userInfosResponse?.content?.verified_email === true){
        this.userInfoApi = userInfosResponse?.content;
        this.isLoading = false;
      }else{
        this.isLoading = false;
        this.router.navigate(['login']);
      }
    }

    public validateKey(){
      this.isValidKey = this.userKey ? this.cryptoService.isValidKey(this.userKey) : false;
      !this.isValidKey ? alert('La clé introduite est invalide') : this.getAccessRight();
    }

    private async getAccessRight(){
      const accessRightsResponse = await this.googleApiService.getFileFromStorage(this.oAuthToken?.access_token!!);
      try {
        const encryptedAccessRights = accessRightsResponse?.content;
        const decryptedAccessRights = this.cryptoService.decrypt(encryptedAccessRights!!, this.userKey);
        const decryptedAccessRightsAsJson = JSON.parse(decryptedAccessRights);
        const accessRights: AccessRight[] = decryptedAccessRightsAsJson?.matrix;
        const keys: Key[] = decryptedAccessRightsAsJson?.keys;
        if(accessRights?.find(access => access?.user === this.userInfoApi?.email)){
          this.accessRight = accessRights?.find(access => access?.user === this.userInfoApi?.email)!!;
          this.keys = keys;
          this.isLoading = false;
        }else{
          this.isLoading = false;
          this.router.navigate(['login']);
        }
      }catch (e){
        console.log("Error while decrypting access rights", e);
      }
    }

    public async login(type: string){
        const key = this.keys?.find(key => key?.type === type);
        const credentials: Credentials = new Credentials({username: this.cryptoService.decrypt(key?.key!!, this.userKey), password: this.cryptoService.decrypt(key?.value!!, this.userKey)});
        const user: User | undefined = await this.auth.login(credentials);
        if(user){
          const groupInfos = await this.api.groupApi?.getGroup(user.groupId!!);
          if(groupInfos?.superAdmin === true){
            const isFreeVersion = groupInfos?.id?.includes('free');
            this.localSettings.setBasicAuth(btoa(credentials.username + ':' + credentials.password));
            this.localSettings.setVersion(isFreeVersion ? VersionType.FREE : VersionType.FULL);
            this.localSettings.setListOfGroupIds([]);
            await this.loginService.redirectConnectedUser(user, 'private');
          }
        }
    }


}
