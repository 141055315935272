import {FetchService} from "./fetch.service";
import {Injectable} from "@angular/core";

export enum DbTypeEnum{
  BASE = 'base',
  HEALTHDATA = 'healthdata',
  PATIENT = 'patient'
}

export interface FetchResponse{
  status: FetchResponseStatus,
  content: any
}

export interface FetchResponseStatus {
  status: number;
  statusText: string;
}


@Injectable({
  providedIn: 'root',
})
export class CouchDbService {

  constructor(
    private fetchService: FetchService
  ) {
  }

  public async createSession(groupId: string, groupPassword: string, cluster: string): Promise<FetchResponse>{
    const resp = await this.fetchService.executeFetchRequest(`${cluster}/_session`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: groupId,
        password: groupPassword
      })
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public async connectToDb(groupId: string, groupPassword: string, cluster: string, dbType: DbTypeEnum): Promise<FetchResponse>{
    const resp = await this.fetchService.executeFetchRequest(`${cluster}/_session?next=/_utils/#database/icure-${groupId}-${dbType}/_all_docs`,{
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        name: groupId,
        password: groupPassword
      })
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }
}
