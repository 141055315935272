import {Component, Input, OnInit} from '@angular/core';
import {AccessLog, HealthcareParty, User} from "@icure/api";
import {AccessLogService} from "../../../../../lib/services/access-log.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'ms-database-group-info-logs',
  templateUrl: './database-group-info-logs.component.html',
  styleUrls: ['./database-group-info-logs.component.scss']
})
export class DatabaseGroupInfoLogsComponent implements OnInit{
  @Input() listOfUsers: Array<User> = [];
  @Input() listOfHcps: Array<HealthcareParty> = [];
  @Input() groupId: string = '';

  public accessLogsInfos: any = [];
  public isLoading: boolean = false;
  // @ts-ignore
  public dataSource: MatTableDataSource;
  public displayedColumns: string[] = ['firstName', 'lastName', 'nihii', 'numberOfConnection_01', 'numberOfConnection_02', 'numberOfConnection_03', 'numberOfConnection_04', 'numberOfConnection_05', 'numberOfConnection_06', 'numberOfConnection_07', 'numberOfConnection_08', 'numberOfConnection_09', 'numberOfConnection_10', 'numberOfConnection_11', 'numberOfConnection_12'];
  public availableYears: Array<string> = [];
  public selectedYear: string = '';
  constructor(
      private accessLogService: AccessLogService
  ) {
  }
  ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.selectedYear = currentYear.toString();
    for(let i = 2022; i <= currentYear; i++){
      this.availableYears.push(i.toString());
    }
  }

  public async initialize(){
    this.isLoading = true;
    this.accessLogsInfos = [];
    const accessLogsInfos: any = {
      "01": [],
      "02": [],
      "03": [],
      "04": [],
      "05": [],
      "06": [],
      "07": [],
      "08": [],
      "09": [],
      "10": [],
      "11": [],
      "12": [],
    };

    accessLogsInfos['01'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-01-01`, `${this.selectedYear}-01-31`);
    accessLogsInfos['02'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-02-01`, `${this.selectedYear}-02-29`);
    accessLogsInfos['03'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-03-01`, `${this.selectedYear}-03-31`);
    accessLogsInfos['04'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-04-01`, `${this.selectedYear}-04-30`);
    accessLogsInfos['05'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-05-01`, `${this.selectedYear}-05-31`);
    accessLogsInfos['06'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-06-01`, `${this.selectedYear}-06-30`);
    accessLogsInfos['07'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-07-01`, `${this.selectedYear}-07-31`);
    accessLogsInfos['08'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-08-01`, `${this.selectedYear}-08-31`);
    accessLogsInfos['09'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-09-01`, `${this.selectedYear}-09-30`);
    accessLogsInfos['10'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-10-01`, `${this.selectedYear}-10-31`);
    accessLogsInfos['11'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-11-01`, `${this.selectedYear}-11-30`);
    accessLogsInfos['12'] = await this.accessLogService?.getAccessLogByGroupId(this.groupId, 500,`${this.selectedYear}-12-01`, `${this.selectedYear}-12-31`);

    this.listOfUsers?.map(usr => {
      const hcp = this.listOfHcps?.find(hcp => hcp.id === usr.healthcarePartyId);
      if(!!hcp?.nihii){
        this.accessLogsInfos.push({
          userId: usr?.id,
          firstName: hcp?.firstName,
          lastName: hcp?.lastName,
          nihii: hcp?.nihii,
          numberOfConnection_01: accessLogsInfos['01']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_02: accessLogsInfos['02']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_03: accessLogsInfos['03']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_04: accessLogsInfos['04']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_05: accessLogsInfos['05']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_06: accessLogsInfos['06']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_07: accessLogsInfos['07']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_08: accessLogsInfos['08']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_09: accessLogsInfos['09']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_10: accessLogsInfos['10']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_11: accessLogsInfos['11']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length,
          numberOfConnection_12: accessLogsInfos['12']?.filter((log: AccessLog) => log.user === usr?.id && log?.accessType === "USER_ACCESS")?.length
        });
      }
    });
    this.dataSource = new MatTableDataSource(this.accessLogsInfos);
    this.isLoading = false;
  }
}
