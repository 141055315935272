import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {HardwareInfoDialogComponent} from "../dialogs/hardware-info-dialog/hardware-info-dialog.component";
import {humanReadableBytes} from "../../../../../lib/utils/function-utils";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {
  DesactivationConfigDialogComponent
} from "../dialogs/desactivation-config-dialog/desactivation-config-dialog.component";
import {MessageTypeEnum, ReleaseActionEnum} from "../../database-group/database-group.component";
import {DesktopService} from "../../../../../lib/services/desktop.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'ms-database-group-info-hardware',
  templateUrl: './database-group-info-hardware.component.html',
  styleUrls: ['./database-group-info-hardware.component.scss']
})
export class DatabaseGroupInfoHardwareComponent implements OnInit{

  @Input() desktopInfos: any = {};
  // @ts-ignore
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public dataSource = new MatTableDataSource();

  public displayedColumns: string[] = [
    'configId',
    'currentVersion',
    'couchDBVersion',
    'electronVersion',
    'cpuModel',
    'cpuCount',
    'ramQty',
    'diskSize',
    'diskSapceFree',
    'diskPath',
    'actions'
  ];
  public isLoading = false;

  constructor(
    private dialog: MatDialog,
    private desktopService: DesktopService,
    private snackBar: MatSnackBar,
  ) {
  }

  public ngOnInit() {
    this.initialize();
  }

  private initialize(){
    this.isLoading = true;
    this.sort.sort({id: '', start: 'asc'} as MatSortable);
    this.sort.sort({id: 'configId', start: 'asc'} as MatSortable);
    this.dataSource = new MatTableDataSource(this.desktopInfos?.content?.configs);
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  public openInfoDialog(selectedConfig: any){
    const dialogRef = this.dialog.open(HardwareInfoDialogComponent, {
      data: selectedConfig,
      height: '600px',
      width: '1000px'
    });
  }

  public getBytes(bytes: number): string{
    return humanReadableBytes(bytes);
  }

  public openConfirmDesactivationDialog(selectedConfig: any){
    const dialogRef = this.dialog.open(DesactivationConfigDialogComponent, {
      data: selectedConfig,
      height: '400px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(async configId => {
      if(configId){
        const resp = await this.desktopService.deleteConfigInGroup(this.desktopInfos?.content?.groupId, configId);
        if(resp?.status?.status === 200){
          this.displayWarningMessage('La config a bien été supprimée du groupe', MessageTypeEnum.SUCCESS);
          const gcResp = await this.desktopService.getGroupConfig(this.desktopInfos?.content?.groupId);
          this.desktopInfos = gcResp;
          this.initialize();
        }else{
          this.displayWarningMessage('Erreur lors de la suppression de la config', MessageTypeEnum.ERROR);
        }
      }
    });
  }

  private displayWarningMessage(message: string, messageType: MessageTypeEnum){
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [messageType]
    });
  }

}
