import {Component, OnInit} from '@angular/core';
import {User} from "@icure/api";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../lib/services/authentication.service";
import {ApiService} from "../../../lib/services/api.service";
import {LocalSettingsService} from "../../../lib/services/local-settings.service";
import {VersionType} from "../../../lib/services/access-matrix.service";
import {GoogleApiService} from "../../../lib/services/google-api.service";
import {CryptoService} from "../../../lib/services/crypto.service";
import {LoginService} from "../../../lib/services/login.service";
import {Credentials} from "../../../lib/models/credentials.model";
import {getAuthenticationProvider} from "../../../lib/authentication/authenticationProviderCache";

export const HOST: string = "https://kraken.icure.cloud/rest/v1"
export const HEADER: any = {
  'Content-Type': 'application/json',
}

@Component({
  selector: 'ms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  public credentials: Credentials = new Credentials({username: '', password: ''});
  public userKey: string = '';

  constructor(
    private auth: AuthenticationService,
    private api: ApiService,
    private router: Router,
    private localSettings: LocalSettingsService,
    private googleApiService: GoogleApiService,
    private cryptoService: CryptoService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.api.reconfigure(HOST, HEADER, getAuthenticationProvider());
  }

  public async login(){
    const user: User | undefined = await this.auth.login(this.credentials)
    if(user){
      const groupInfos = await this.api.groupApi?.getGroup(user.groupId!!);
      if(groupInfos?.superAdmin === true){
        const isFreeVersion = groupInfos?.id?.includes('free');
        this.localSettings.setBasicAuth(btoa(this.credentials.username + ':' + this.credentials.password));
        this.localSettings.setVersion(isFreeVersion ? VersionType.FREE : VersionType.FULL);
        this.localSettings.setListOfGroupIds([]);
        await this.loginService.redirectConnectedUser(user, 'private');
      }
    }
  }

  public openOAuthApi(){
    window.location.href = this.googleApiService.getOAuthAuthorizationUrl();
  }
}
