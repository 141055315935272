import {AuthenticationProvider, NoAuthenticationProvider} from "@icure/api/icc-x-api/auth/AuthenticationProvider";

let authenticationProvider: AuthenticationProvider = new NoAuthenticationProvider();
const getAuthenticationProvider: () => AuthenticationProvider = () => authenticationProvider;

const setAuthenticationProvider: (prov: AuthenticationProvider) => AuthenticationProvider = prov => {
    if (prov?.constructor.name === 'JwtAuthenticationProvider') {
        authenticationProvider = prov;
    }
    return getAuthenticationProvider();
};

export { getAuthenticationProvider, setAuthenticationProvider };
