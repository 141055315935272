import {AccessLog, PaginatedListAccessLog, retry} from "@icure/api";
import {ApiService} from "./api.service";
import {getRowsUsingPagination} from "../utils/function-utils";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AccessLogService {
  constructor(
    private api: ApiService
  ) {
  }

  public async getAccessLogByGroupId(groupId: string, limit: number | undefined = 500, startDate?: string, endDate?: string, ): Promise<any>{

    const from = startDate ? new Date(startDate).getTime() : undefined;
    const to = endDate ? new Date(endDate).getTime() : undefined;

    const accessLogs: PaginatedListAccessLog[] = await getRowsUsingPagination<PaginatedListAccessLog[]>((startKey: number, startDocumentId: string) =>
      retry(() => this.api.accessLogApi?.listAccessLogsInGroup(groupId, from, to, startKey, startDocumentId, limit)!!, 0)
    ).catch(e => console.warn(e)) || [];

    return accessLogs;
  }

  public async getAccessLogByGroupIdWithoutPagination(groupId: string, limit: number | undefined = 500, startDate?: string, endDate?: string, ): Promise<any>{

    const from = startDate ? new Date(startDate).getTime() : undefined;
    const to = endDate ? new Date(endDate).getTime() : undefined;

    return await this.api.accessLogApi?.listAccessLogsInGroup(groupId, from, to, undefined, undefined, limit).catch(e => console.warn(e))!! || [];
  }

  public getAccessLogByUserId(userId: string, accessLogs: AccessLog[], accessType?: string): AccessLog[]{
    return accessLogs?.filter(accessLog => accessLog?.user === userId);
  }
}
