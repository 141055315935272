import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {HealthcareParty, ListOfIds} from "@icure/api";

@Injectable({
  providedIn: 'root',
})
export class HcpService {
  constructor(
    private api: ApiService
  ) {
  }

  public async getHcpsInGroup(groupId: string, hcpIds: Array<string>): Promise<Array<HealthcareParty>>{
    return this.api.hcPartyApi?.getHealthcarePartiesInGroup(groupId, new ListOfIds({ ids: hcpIds.filter(x => !!x) })) || [];
  }

  public async addHcpInGroup(groupId: string, hcp: HealthcareParty): Promise<HealthcareParty | undefined>{
    return this.api.hcPartyApi?.createHealthcarePartyInGroup(groupId, hcp);
  }
}
