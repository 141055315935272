<mat-toolbar id="searchBar">
  <div class="searchInput">
    <mat-form-field class="full-width">
      <mat-label>Recherche</mat-label>
      <input matInput [(ngModel)]="selectedFilter" (keyup)="filterChanged()">
    </mat-form-field>
  </div>
  <div class="extraInput">
    <section>
      <mat-checkbox labelPosition="after" [(ngModel)]="activeUser" (click)="filterChanged()">
        Actif
      </mat-checkbox>
      <mat-checkbox labelPosition="after" [(ngModel)]="inactiveUser" (click)="filterChanged()">
        Inactif
      </mat-checkbox>
    </section>
    <section class="mr-l-10">
      <button mat-flat-button color="primary" (click)="openUserCreationDialog()">Créer un utilisateur</button>
    </section>
  </div>
</mat-toolbar>
<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="creationDateHr">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de création</th>
    <td mat-cell *matCellDef="let element">{{element?.creationDateHr}}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="element?.parentId !== ''" class="table-icon green" title="ParentId défini">star</mat-icon>
      <mat-icon *ngIf="element?.parentId === ''" class="table-icon red" title="Pas de parentId défini">star</mat-icon>
      {{element?.name}}
    </td>
  </ng-container>
  <ng-container matColumnDef="login">
    <th mat-header-cell *matHeaderCellDef>Login</th>
    <td mat-cell *matCellDef="let element">
      {{element?.login}}
    </td>
  </ng-container>
  <ng-container matColumnDef="nihii">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Inami</th>
    <td mat-cell *matCellDef="let element">{{element?.nihii}}</td>
  </ng-container>
  <ng-container matColumnDef="speciality">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Spécialité</th>
    <td mat-cell *matCellDef="let element">{{element?.speciality}}</td>
  </ng-container>
  <ng-container matColumnDef="ssin">
    <th mat-header-cell *matHeaderCellDef>Niss</th>
    <td mat-cell *matCellDef="let element">{{element?.ssin}}</td>
  </ng-container>
  <ng-container matColumnDef="street">
    <th mat-header-cell *matHeaderCellDef>Rue</th>
    <td mat-cell *matCellDef="let element">{{element?.adr?.street}} {{element?.adr?.houseNumber}}</td>
  </ng-container>
  <ng-container matColumnDef="postalCode">
    <th mat-header-cell *matHeaderCellDef>Code postal</th>
    <td mat-cell *matCellDef="let element">{{element.adr?.postalCode}}</td>
  </ng-container>
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef>Ville</th>
    <td mat-cell *matCellDef="let element">{{element.adr?.city}}</td>
  </ng-container>
  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef>Pays</th>
    <td mat-cell *matCellDef="let element">{{element.adr?.country}}</td>
  </ng-container>
  <ng-container matColumnDef="steps">
    <th mat-header-cell *matHeaderCellDef>Etapes</th>
    <td mat-cell *matCellDef="let element">
      <div class="steps">
        <div id="step_1" title="Définition du mdp">
          <mat-icon *ngIf="element?.steps?.step_1 === true" class="table-icon green">check_circle</mat-icon>
          <mat-icon *ngIf="element?.steps?.step_1 === false" class="table-icon red">cancel</mat-icon>
        </div>
        <div id="step_2" title="Données du hcp">
          <mat-icon *ngIf="element?.steps?.step_2 === true" class="table-icon green">check_circle</mat-icon>
          <mat-icon *ngIf="element?.steps?.step_2 === false" class="table-icon red">cancel</mat-icon>
        </div>
        <div id="step_3" title="Adresse">
          <mat-icon *ngIf="element?.steps?.step_3 === true" class="table-icon green">check_circle</mat-icon>
          <mat-icon *ngIf="element?.steps?.step_3 === false" class="table-icon red">cancel</mat-icon>
        </div>
        <div id="step_4" title="Données de facturation">
          <mat-icon *ngIf="element?.steps?.step_4 === true" class="table-icon green">check_circle</mat-icon>
          <mat-icon *ngIf="element?.steps?.step_4 === false" class="table-icon red">cancel</mat-icon>
        </div>
        <div id="step_5" title="Génération de la clé">
          <mat-icon *ngIf="element?.steps?.step_5 === true" class="table-icon green">check_circle</mat-icon>
          <mat-icon *ngIf="element?.steps?.step_5 === false" class="table-icon red">cancel</mat-icon>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="licenceStartDate">
    <th mat-header-cell *matHeaderCellDef>Déb. licence</th>
    <td mat-cell *matCellDef="let element">{{element?.licence?.startDate}}</td>
  </ng-container>
  <ng-container matColumnDef="licenceEndDate">
    <th mat-header-cell *matHeaderCellDef>Fin licence</th>
    <td mat-cell *matCellDef="let element">{{element?.licence?.endDate}}</td>
  </ng-container>
  <ng-container matColumnDef="statusHr">
    <th mat-header-cell *matHeaderCellDef>Statut</th>
    <td mat-cell *matCellDef="let element">{{element?.statusHr}}</td>
  </ng-container>
  <ng-container matColumnDef="freeStatusHr">
    <th mat-header-cell *matHeaderCellDef>Statut</th>
    <td mat-cell *matCellDef="let element">{{element?.freeStatusHr}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
