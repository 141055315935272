<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
    <td mat-cell *matCellDef="let element">{{element?.id}}</td>
  </ng-container>
  <ng-container matColumnDef="settings">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Settings</th>
    <td mat-cell *matCellDef="let element">{{element?.settings}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
