import {Injectable} from "@angular/core";
import {VersionType} from "./access-matrix.service";
import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store";
import {Group} from "@icure/api";

export class ConfigureVersionType{
  static readonly type = '[LocalSettingsState] Configure version type';
  constructor(public versionType: VersionType) {}
}

export class ConfigureListOfGroups{
  static readonly type = '[LocalSettingsState] Configure list of groups';
  constructor(public listOfGroups: Array<Group>) {}
}

interface LocalSettingsStateModel {
  versionType: VersionType,
  listOfGroups: Array<Group>
}

@Injectable()
@State<LocalSettingsStateModel>({
  name: 'LocalSettingsState',
  defaults: {
    versionType: 'free' as VersionType,
    listOfGroups: []
  }
})

@Injectable()
export class LocalSettingsState implements NgxsOnInit{

  // @ts-ignore
  private onInitialized: () => void;
  public initialized: Promise<void>;

  constructor() {
    this.initialized = new Promise(resolve => (this.onInitialized = resolve));
  }

  @Selector()
  static versionType(state: LocalSettingsStateModel): VersionType{
    return state.versionType;
  }

  @Action(ConfigureVersionType)
  configureVersionType({patchState}: StateContext<LocalSettingsStateModel>, { versionType }: ConfigureVersionType){
    patchState({versionType: versionType});
  }

  @Selector()
  static listOfGroups(state: LocalSettingsStateModel): Array<Group>{
    return state.listOfGroups;
  }

  @Action(ConfigureListOfGroups)
  configureListOfGroups({patchState}: StateContext<LocalSettingsStateModel>, { listOfGroups }: ConfigureListOfGroups){
    patchState({listOfGroups: listOfGroups})
  }

  ngxsOnInit({getState, dispatch}: StateContext<LocalSettingsStateModel>) {
    const defaultConfigs = VersionType.FREE;
    const config: VersionType = defaultConfigs;

    dispatch(new ConfigureVersionType(config));
    this.onInitialized();
  }
}
