import {VersionType} from "./access-matrix.service";
import {ConfigureListOfGroups, ConfigureVersionType, LocalSettingsState} from "./local-settings.state";
import {Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {Group} from "@icure/api";

@Injectable({
  providedIn: 'root',
})
export class LocalSettingsService {
  initialized: Promise<void>;
  private _basicAuth: string = '';
  constructor(
    private store: Store,
    private localSettingsState: LocalSettingsState
  ) {
    this.initialized = this.localSettingsState.initialized;
  }

  public setVersion(version: VersionType){
    this.store.dispatch(new ConfigureVersionType(version))
  }

  public get version(): VersionType{
      return this.store.selectSnapshot(LocalSettingsState.versionType)
  }

  public setListOfGroupIds(groups: Array<Group>){
    this.store.dispatch(new ConfigureListOfGroups(groups));
  }

  public get listOfGroups(): Array<Group>{
    return this.store.selectSnapshot(LocalSettingsState.listOfGroups);
  }

  public setBasicAuth(basicAuth: string){
    this._basicAuth = basicAuth;
  }

  public get basicAuth(): string{
    return this._basicAuth;
  }
}
