<div class="table mb5">
  <div class="tr">
    <div class="td w100">
      <span class="font-weight-bold">Group id:</span> {{selectedGroup?.id}}
      <mat-icon class="clipboard-icon" (click)="copyToClipboard(selectedGroup?.id)">library_books</mat-icon>
    </div>
  </div>
  <div class="tr">
    <div class="td w100">
      <span class="font-weight-bold">Group password:</span> {{selectedGroup?.password}}
      <mat-icon class="clipboard-icon" (click)="copyToClipboard(selectedGroup?.password)">library_books</mat-icon>
    </div>
  </div>
</div>

<table mat-table [dataSource]="groupStorageInfos?.databasesInfo">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Id</th>
    <td mat-cell *matCellDef="let element">{{element?.id}}</td>
  </ng-container>
  <ng-container matColumnDef="docNumber">
    <th mat-header-cell *matHeaderCellDef>Nb doc</th>
    <td mat-cell *matCellDef="let element">{{element?.docs}}</td>
  </ng-container>
  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef>Taille</th>
    <td mat-cell *matCellDef="let element">{{getSize(element?.fileSize)}} MB</td>
  </ng-container>
  <ng-container matColumnDef="url">
    <th mat-header-cell *matHeaderCellDef>Url</th>
    <td mat-cell *matCellDef="let element">{{createConnectionUrl(element?.id)}}</td>
  </ng-container>
  <ng-container matColumnDef="btn">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="redirectToFauxton(element?.id)">
        <mat-icon>open_in_new</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
