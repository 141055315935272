import {Component, OnInit} from '@angular/core';
import {GroupService} from "../../../lib/services/group.service";
import {Group} from "@icure/api";
import {UserInfo} from "../../../lib/models/user.model";
import {UserService} from "../../../lib/services/user.service";
import {
  ActivityIndicatorWithMessages
} from "../../../lib/activity-indicator-with-messages/activity-indicator-with-messages.component";
import {CL1, CL2} from "../database/database-group/database-group.component";
import * as _ from 'lodash';
import {
  AccessMatrixService,
  ModuleTypeEnum,
  RouteTypeEnum,
  SubModuleTypeEnum,
  VersionType
} from "../../../lib/services/access-matrix.service";
import {LocalSettingsService} from "../../../lib/services/local-settings.service";
import {DashboardWidgetDatas, WidgetService} from "../../../lib/services/widget.service";
import {DesktopService} from "../../../lib/services/desktop.service";

@Component({
  selector: 'ms-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{

  public isLoading: boolean = false;
  public listOfGroups: Array<Group> = [];
  public userInfos: Array<UserInfo> = [];
  public messages: Array<ActivityIndicatorWithMessages> = [];
  private currentVersion: VersionType = VersionType.FREE;

  public widgets: DashboardWidgetDatas = {
    numberOfDb: 0,
    newUserLast24h: 0,
    cluster_1: 0,
    cluster_2: 0,
    steps: {
      step_1: 0,
      step_2: 0,
      step_3: 0,
      step_4: 0,
      step_5: 0,
      step_6: 0
    },
    duplicate: 0,
    districtRepartition: {
      bxl: 0,
      fla: 0,
      wal: 0
    },
    numberOfActiveDb: 0
  };

  constructor(
    private groupService: GroupService,
    private userService: UserService,
    public accessMatrixService: AccessMatrixService,
    private localSettingsService: LocalSettingsService,
    private widgetService: WidgetService,
    private desktopService: DesktopService
  ) {
  }

  ngOnInit() {
    if (!this.userInfos?.length) {
      this.currentVersion = this.localSettingsService.version
      this.initialize()
    }
  }


  private async initialize(){
    this.messages = [];
    this.isLoading = true;
    this.messages.push({message: 'Création des widgets', isDone: false});

    this.listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.getAllGroups();
    const activeGroupsResp = this.accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_DESKTOP_API) ? await this.desktopService.listGroups() : null;
    const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
    const listOfUsers = this.currentVersion === VersionType.FREE ?  await this.userService.getFullUserInfos(this.listOfGroups) : [];
    this.widgets = this.widgetService.initializeDashboardWidgets(listOfUsers, this.listOfGroups, activeGroups)
    this.userInfos = listOfUsers;
    this.isLoading = false;
  }

  public get RouteTypeEnum(){
    return RouteTypeEnum
  }

  public get ModuleTypeEnum(){
    return ModuleTypeEnum
  }

  public get SubModuleTypeEnum(){
    return SubModuleTypeEnum
  }

  private async getAllGroups(): Promise<Array<Group>>{
    const listOfGroups = await this.groupService.getAllGroups();
    this.localSettingsService.setListOfGroupIds(listOfGroups);
    return listOfGroups;
  }

}
