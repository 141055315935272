import {Injectable} from "@angular/core";
import {UserInfo} from "../models/user.model";
import {Group} from "@icure/api";
import {CL1, CL2} from "../../app/private/database/database-group/database-group.component";
import {UserService} from "./user.service";
import * as _ from "lodash";

export interface DistrictRepartitionCounter{
  bxl: number;
  fla: number;
  wal: number;
}

export interface StepCounter{
  step_1: number; //User never connected
  step_2: number; //Password configuration
  step_3: number; //Hcp configuration
  step_4: number; //Adr configuration
  step_5: number; //Invoice configuration
  step_6: number; //User fully configured
}

export interface DashboardWidgetDatas{
  numberOfDb: number;
  cluster_1: number;
  cluster_2: number;
  newUserLast24h: number;
  steps: StepCounter;
  duplicate: number;
  districtRepartition: DistrictRepartitionCounter;
  numberOfActiveDb: number;
}

@Injectable({
  providedIn: 'root',
})
export class WidgetService {

  constructor(
    private userService: UserService
  ) {
  }

  public initializeDashboardWidgets(listOfUsers: Array<UserInfo>, listOfGroups: Array<Group>, allActiveDbs: Array<any>): DashboardWidgetDatas{
    const groupedUserByName = _.groupBy(listOfUsers, 'name');
    const widgets: DashboardWidgetDatas = {
      numberOfDb: listOfGroups?.length,
      cluster_1: listOfGroups?.filter(group => group?.servers?.some(srv => srv.includes(CL1)))?.length,
      cluster_2: listOfGroups?.filter(group => group?.servers?.some(srv => srv.includes(CL2)))?.length,
      newUserLast24h: 0,
      steps: {
        step_1: 0,
        step_2: 0,
        step_3: 0,
        step_4: 0,
        step_5: 0,
        step_6: 0
      },
      duplicate: 0,
      districtRepartition: {
       bxl: 0,
       fla: 0,
       wal: 0
      },
      numberOfActiveDb: allActiveDbs?.length || 0
    }

    listOfUsers.map(user => {
      if(user.creationDate?.getTime()) {
        const msBetweenDates = Math.abs(user.creationDate?.getTime() - new Date().getTime())
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        hoursBetweenDates < 24 ? widgets.newUserLast24h++ : null;
      }

      if(user?.adr?.postalCode && !isNaN(Number(user?.adr?.postalCode))){
        const district: string = this.userService.getDistrictOfPatient(Number(user?.adr?.postalCode));
        widgets.districtRepartition[district as keyof DistrictRepartitionCounter]++;
      }

      widgets.steps = this.extractUserStep(user, widgets.steps);
      widgets.duplicate = Object.keys(groupedUserByName)?.map(name => groupedUserByName[name])?.filter(matches => matches?.length > 1)?.length;
    });

    return widgets;
  }

  private extractUserStep(user: UserInfo, stepCounter: StepCounter): StepCounter{
    user.steps?.step_1 === false && user.steps?.step_2 === false  && user.steps?.step_3 === false && user.steps?.step_4 === false && user.steps?.step_5 === false ? stepCounter.step_1++ : null;
    user.steps?.step_1 === true && user.steps?.step_2 === false  && user.steps?.step_3 === false && user.steps?.step_4 === false && user.steps?.step_5 === false ? stepCounter.step_2++ : null;
    user.steps?.step_1 === true && user.steps?.step_2 === true  && user.steps?.step_3 === false && user.steps?.step_4 === false && user.steps?.step_5 === false ? stepCounter.step_3++ : null;
    user.steps?.step_1 === true && user.steps?.step_2 === true  && user.steps?.step_3 === true && user.steps?.step_4 === false && user.steps?.step_5 === false ? stepCounter.step_4++ : null;
    user.steps?.step_1 === true && user.steps?.step_2 === true  && user.steps?.step_3 === true && user.steps?.step_4 === true && user.steps?.step_5 === false ? stepCounter.step_5++ : null;
    user.steps?.step_5 === true ? stepCounter.step_6++ : null;

    return stepCounter;
  }

}
