import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Address, Group, HealthcareParty, PaginatedListUser, User} from "@icure/api";
import {AssistantInfos, DESKTOP_API_CREDENTIALS_PROP, LicenceInfos, UserInfo} from "../models/user.model";
import * as _ from "lodash";
import {KEYS_PROP, LICENCE_KEY} from "../../app/private/database/database-user/database-user.component";
import {HcpService} from "./hcp.service";
import AddressTypeEnum = Address.AddressTypeEnum;
import {
  isDoctor,
  isMidwife,
  isNurse,
  isPhysiotherapist,
  isSpeechTherapist,
  physicianSpecialityList
} from "../utils/speciality-utils";
import {PropertyStub} from "@icure/api/icc-api/model/PropertyStub";
import {DesktopApiCredentials} from "../models/desktop.model";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private postalCodeInfos = [
    {
      min: 1000,
      max: 1299,
      counterName: 'bxl'
    },
    {
      min: 1300,
      max: 1499,
      counterName: 'wal'
    },
    {
      min: 1500,
      max: 1999,
      counterName: 'fla'
    },
    {
      min: 2000,
      max: 2999,
      counterName: 'fla'
    },
    {
      min: 3000,
      max: 3499,
      counterName: 'fla',
    },
    {
      min: 3500,
      max: 3999,
      counterName: 'fla'
    },
    {
      min: 4000,
      max: 4999,
      counterName: 'wal'
    },
    {
      min: 5000,
      max: 5680,
      counterName: 'wal'
    },
    {
      min: 6000,
      max: 6599,
      counterName: 'wal'
    },
    {
      min: 6600,
      max: 6999,
      counterName: 'wal'
    },
    {
      min: 7000,
      max: 7999,
      counterName: 'wal'
    },
    {
      min: 8000,
      max: 8999,
      counterName: 'fla'
    },
    {
      min: 9000,
      max: 9999,
      counterName: 'fla'
    }
  ];

  constructor(
    private api: ApiService,
    private hcpService: HcpService
  ) {}

  public async getUserInGroup(groupId: string, startKey: string | null = null, startKeyDocId: string | null = null, limit: number = 100): Promise<any>{
    const allUsers = [];
    let pageEnd = false;
    while(!pageEnd){
      const userPage: PaginatedListUser = await this.api.userApi?.listUsersInGroup(groupId, startKey!!, startKeyDocId!!, limit)!!;
      pageEnd = !userPage?.nextKeyPair;
      if(!pageEnd){
        startKey = userPage?.nextKeyPair?.startKey || null;
        startKeyDocId = userPage?.nextKeyPair?.startKeyDocId || null;
      }
      allUsers.push(userPage?.rows);
    }
    return allUsers.flat(Infinity);
  }

  public async getFullUserInfos(groups: Group[]): Promise<UserInfo[]>{
    let prom: Promise<any> = Promise.resolve();
    const listOfUsers: Array<UserInfo> = [];
    _.chunk(groups, 25).map(
      (groupChunk: Group[]) => (
        prom = prom.then(() =>
          Promise.all(
            groupChunk.map(
              (group: Group) => this.getUserInGroup(group.id!!, null, null, 500)
                .then(async users => {
                  const hcps: HealthcareParty[] = await this.hcpService.getHcpsInGroup(group.id!!, users?.map((usr: User) => usr?.healthcarePartyId!!)) || [];
                  users?.map((user: User) => {
                    const hcp: HealthcareParty = hcps?.find(hcp => hcp?.id === user?.healthcarePartyId) || {};
                    const hcpAdr: Address = hcp?.addresses?.find(adr => adr?.addressType === AddressTypeEnum.Work) || {};
                    const creationDate: Date = new Date(user?.createdDate!!)
                    const hasKeys: boolean = hcp?.hcPartyKeys ? !!Object?.keys(hcp?.hcPartyKeys as object)?.length : false;
                    listOfUsers.push({
                      groupId: group.id || '',
                      groupPassword: group.password || '',
                      userId: user.id || '',
                      creationDate: creationDate,
                      creationDateHr: `${creationDate.getDate().toString().padStart(2, '0')}/${(creationDate.getMonth() +1).toString().padStart(2, '0')}/${creationDate.getFullYear()}`,
                      login: user.login || '',
                      ssin: hcp?.ssin || '',
                      nihii: hcp?.nihii || hcp?.options?.['inami'] ||  '',
                      isKeyGenerate: user?.properties?.find(prop => prop?.type?.identifier === KEYS_PROP)?.typedValue?.booleanValue || false,
                      adr: {
                        street: hcpAdr?.street || "",
                        houseNumber: hcpAdr?.houseNumber || "",
                        city: hcpAdr?.city || "",
                        country: hcpAdr?.country || "",
                        postalCode: hcpAdr?.postalCode || ""
                      },
                      name: group.name,
                      speciality: this.getUserSpeciality(hcp?.nihii || hcp?.options?.['inami']!!),
                      steps: {
                        step_1: !!user?.passwordHash,
                        step_2: !!hcp?.nihii,
                        step_3: !!hcpAdr?.postalCode,
                        step_4: !!hcp?.bankAccount,
                        step_5: hasKeys || false,
                      },
                      statusHr: !user?.passwordHash ? 'Inactif' : hasKeys ? 'Actif' : !!user?.passwordHash && !hasKeys ? 'Bloqué' : ''
                    })
                  })
                }).catch(e => console.log(e))
            )
          ).catch(e => console.log(e))
        )
      )
    )
    return prom.then(() => {
      return listOfUsers;
    })
  }

  public getUserSpeciality(nihii: string): string{
    let speciality: string = '';
    if(isDoctor(nihii)){
      speciality = physicianSpecialityList.find(spe => spe.code === nihii.substring(8, 11))?.speciality?.fr || ''
    }else if(isNurse(nihii)){
      speciality = 'Infirmier(e)'
    }else if(isPhysiotherapist(nihii)){
      speciality = 'Kiné'
    }else if(isMidwife(nihii)){
      speciality = 'Sage-femme'
    }else if(isSpeechTherapist(nihii)){
      speciality = 'Logopède'
    }else if(!nihii){
      speciality = 'Indéfini'
    }else{
      speciality = 'Autre'
    }
    return speciality;
  }

  public getDistrictOfPatient(postalCode: number): string{
    return this.postalCodeInfos?.find(pci => postalCode >= pci.min && postalCode <= pci.max)?.counterName!!
  }

  public getLicenceInfos(property: PropertyStub): LicenceInfos{
    let licenceInfos : LicenceInfos = {
      licenceType: '',
      startDate: '',
      endDate: ''
    };

    if(property){
      try{
        const licence = property?.typedValue?.stringValue;
        const licenceValue = licence?.split('-medi-')?.[0];
        const parsedLicence = JSON.parse(licenceValue!!);
        licenceInfos = {
          startDate: parsedLicence?.startDate,
          endDate: parsedLicence?.endDate,
          licenceType: parsedLicence?.licenceType
        }
      }catch (e) {
        console.warn('Impossible to parse user licence')
      }
    }
    return licenceInfos;
  }

  public getAssistantInfos(property: PropertyStub): AssistantInfos{
    let assistantInfos: AssistantInfos = {
      isNextStructureTheSame: false,
      nextStatus: '',
      nextSupervisorId: '',
      validationDate: ''
    };

    if(property){
      try{
        const assistantInvoicing = property?.typedValue?.stringValue;
        const parsedAssistantInvoicing = JSON.parse(assistantInvoicing!!);

        assistantInfos = {
          isNextStructureTheSame: parsedAssistantInvoicing?.isNextStructureTheSame === 'true',
          nextStatus: parsedAssistantInvoicing?.nextStatus,
          nextSupervisorId: parsedAssistantInvoicing?.nextSupervisorId,
          validationDate: parsedAssistantInvoicing?.validationDate
        }

      }catch (e) {
        console.warn('Impossible to parse assistant info')
      }
    }
    return assistantInfos;
  }

  public isUserHasLicence(property: PropertyStub): boolean{
    let licenceValue: string | undefined = '';
    if(property){
      try{
        const licence = property?.typedValue?.stringValue;
         licenceValue = licence?.split('-medi-')?.[0];
      }catch (e) {
        console.warn('Impossible to parse user licence')
      }
    }
    return !!licenceValue;
  }

  public async getDesktopApiCredentials(env: string): Promise<DesktopApiCredentials> {
    let desktopApiCredentials: DesktopApiCredentials[] = [];
    try{
      const user: User | undefined = await this.api.userApi?.getCurrentUser();
      const desktopApiCredentialsProp: PropertyStub | undefined = user?.properties?.find(prop => prop?.type?.identifier === DESKTOP_API_CREDENTIALS_PROP);
      desktopApiCredentials = JSON.parse(desktopApiCredentialsProp?.typedValue?.stringValue || '[]');
    }catch (e) {
      console.warn('Impossible to get desktop api credentials from user')
    }
    return desktopApiCredentials?.find(cred => cred?.env === env)!!;
  }

  public async addUserInGroup(groupId: string, user: {
    healthcarePartyId: string;
    roles: string[] | undefined;
    name: `${string} ${string}` | `${string} undefined` | `undefined ${string}` | "undefined undefined";
    id: string;
    login: string;
    autoDelegations: { [key: string]: Array<string>; };
    type: User.TypeEnum;
    email: string;
    passwordHash: any;
    applicationTokens: {[key: string]: string;},
    status: User.StatusEnum,
  }){
    return this.api.userApi?.createUserInGroup(groupId, user);
  }
}
