import {Injectable} from "@angular/core";
import {ReportingInfo} from "../models/reporting.model";
import {LocalSettingsService} from "./local-settings.service";
import {GroupService} from "./group.service";
import {UserService} from "./user.service";
import {Group, GroupDatabasesInfo, HealthcareParty, PaginatedListAccessLog, User} from "@icure/api";
import * as XLSX from 'xlsx';
import {getDbSize} from "../utils/tools-utils";
import {DesktopService} from "./desktop.service";
import {ASSISTANT_INFO, LICENCE_KEY} from "../../app/private/database/database-user/database-user.component";
import {HcpService} from "./hcp.service";
import {FetchResponse} from "./couch-db.service";
import {AvailableRelease, GroupRelease, GroupReleaseInfos} from "../models/group.model";
import {RolloutTypeEnum, UpdateTypeEnum} from "../../app/private/database/database-group/database-group.component";
import {AccessLogService} from "./access-log.service";
import {AccessLog} from "@icure/api/icc-api/model/AccessLog";
import {AssistantInfos} from "../models/user.model";
import {ApplicationSettingsService} from "./application-settings.service";

@Injectable({
  providedIn: 'root',
})
export class ReportingService {

    constructor(
      private localSettingsService: LocalSettingsService,
      private groupService: GroupService,
      private userService: UserService,
      private desktopService: DesktopService,
      private hcpService: HcpService,
      private accessLogService: AccessLogService,
      private applicationSettingsService: ApplicationSettingsService
    ) {
    }

    public async launchReporting(reportinInfo: ReportingInfo){
      switch (reportinInfo.name){
        case 'getAllUsersByGroupId': await this.getAllUsersByGroupId(); break;
        case 'getLicenceOfAllUsersByGroupId': await this.getLicenceOfAllUsersByGroupId(); break;
        case 'getActiveDBFromDate': await this.getActiveDbFromDate(); break;
        case 'getAssitantInvoicingValidation': await this.getAssitantInvoicingValidation(); break;
        case 'getDatabaseOriginSetting': await this.getDatabaseOriginSetting(); break;
        default: console.log('reporting not found'); break;
      }
    }

    private async getAllUsersByGroupId(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const activeGroupsResp = await this.desktopService.listGroups();
      const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const listOfUserByGroupId: any[] = [];
      const getAvailableReleaseVersionsResponse: FetchResponse = await this.desktopService?.getAvailableReleaseVersions();
      const availableReleaseVersions = getAvailableReleaseVersionsResponse?.content;
      const allGroupsReleaseInfos = await this.desktopService.getReleaseInfos(availableReleaseVersions?.filter((version: any) => version?.startsWith('3')));

      for(let group of listOfGroups){
        cpt++;
        let dbSize = {
          base: '',
          healthdata: '',
          patient: '',
        }
        const groupStorageInfos: GroupDatabasesInfo | null = await this.groupService.getGroupStorageInfos(group.id!!).catch(e => null);
        groupStorageInfos?.databasesInfo?.map(infos => {
          const splitedId = infos?.id?.split('-');
          const dbType = splitedId?.[splitedId.length -1];
          // @ts-ignore
          dbSize[dbType] = getDbSize(infos?.fileSize);
        })
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const activeUsers = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId && !!user?.email);
        listOfUserByGroupId.push({
          groupId: group.id,
          email: activeUsers?.map(user => user.email).join(', '),
          baseSize: dbSize?.base,
          healthDataSize: dbSize?.healthdata,
          patientSize: dbSize?.patient,
          activeDb: activeGroups?.includes(group.id!!)?.toString(),
          release3xx: !!allGroupsReleaseInfos?.filter(gri => gri?.groupIds?.find(gid => gid?.groupId === group?.id))?.length
        });
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getAllUsersByGroupId.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "allUsersByGroupId");
      XLSX.writeFile(workbook, sheetName);
    }

    public async getDatabaseOriginSetting(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfGroupIds: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const settings: FetchResponse | void = await this.desktopService.getAllApplicationSettings(group.id!!, group.password!!, group.servers?.[0]?.replace('https://', '')?.replace('.icure.cloud', '')?.replace(':444', '')!!).catch(e => console.warn('Cannot find application settings', e))
        // @ts-ignore
        if(settings?.status?.status === 200){
          // @ts-ignore
          const databaseOriginSetting = settings?.content?.find(setting => setting?._id === 'APPSETTINGS|DATABASE-ORIGIN|1.0.0') || {};
          const parsedDatabaseOriginSetting = this.applicationSettingsService.getDatabaseOriginInfos(databaseOriginSetting);
          listOfGroupIds.push({
            groupId: group.id,
            databaseOrigin: parsedDatabaseOriginSetting?.databaseOrigin,
            iCureMacDatabase: parsedDatabaseOriginSetting?.iCureMacDatabase
          })
        }else{
          listOfGroupIds.push({
            groupId: group.id,
            databaseOrigin: 'Error when parsing settings, no result to show',
            iCureMacDatabase: false
          })
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfGroupIds);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getDatabaseOriginSetting.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getDatabaseOriginSetting");
      XLSX.writeFile(workbook, sheetName);
    }

    public async getLicenceOfAllUsersByGroupId(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfUserByGroupId: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const activeUsersWithLicence = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId);
        const listOfHcps: HealthcareParty[] | null = await this.hcpService.getHcpsInGroup(group.id!!, activeUsersWithLicence?.map(usr => usr?.healthcarePartyId!!)).catch(e => null);

        if(activeUsersWithLicence?.length){
          for(let user of activeUsersWithLicence){
            const hcp = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId);
            const licenceInfos = this.userService.getLicenceInfos(user?.properties?.find(prop => prop?.type?.identifier === LICENCE_KEY));
            listOfUserByGroupId.push({
              groupId: group.id,
              email: user?.email,
              licenceStartDate: licenceInfos?.startDate || '-',
              licenceEndDate: licenceInfos?.endDate || '-',
              licenceType: licenceInfos?.licenceType ||'-',
              firstName: hcp?.firstName || '',
              lastName:hcp?.lastName || '',
              inami: hcp?.nihii || '',
              ssin: hcp?.ssin || '',
            });
          }
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getLicenceOfAllUsersByGroupId.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getLicenceOfAllUsersByGroupId");
      XLSX.writeFile(workbook, sheetName);
    }

    public async getAssitantInvoicingValidation(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const listOfUserByGroupId: any[] = [];
      for(let group of listOfGroups){
        cpt++;
        const listOfUser: Array<User> = await this.userService.getUserInGroup(group.id!!, null, null, 500).catch(e => null);
        const userWithAssistantInfo = listOfUser?.filter(user => user.status === User.StatusEnum.ACTIVE && !user?.patientId && user?.properties?.find(prop => prop?.type?.identifier === 'be.medispring.preferences.assistantInvoicingInformation'));
        const listOfHcps: HealthcareParty[] | null = await this.hcpService.getHcpsInGroup(group.id!!, userWithAssistantInfo?.map(usr => usr?.healthcarePartyId!!)!!).catch(e => null);

        if(userWithAssistantInfo?.length){
          for(let user of userWithAssistantInfo){
            const hcp = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId);
            const assistantInfos: AssistantInfos = this.userService.getAssistantInfos(user?.properties?.find(prop => prop?.type?.identifier === ASSISTANT_INFO));
            listOfUserByGroupId.push({
              groupId: group.id,
              email: user?.email,
              assistantValidationDate: assistantInfos?.validationDate || '-',
              isNextStructureTheSame: assistantInfos?.isNextStructureTheSame || '-',
              nextStatus: assistantInfos?.nextStatus ||'-',
              nextSupervisorId: assistantInfos?.nextSupervisorId || '',
              supervisorId: hcp?.supervisorId || '',
              firstName: hcp?.firstName || '',
              lastName:hcp?.lastName || '',
              inami: hcp?.nihii || '',
              ssin: hcp?.ssin || '',
            });
          }
        }
        console.log(cpt+'/'+listOfGroups.length);
      }

      var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listOfUserByGroupId);
      const workbook = XLSX.utils.book_new();
      const sheetName: string = `${new Date().getTime().toString()}_getAssitantInvoicingValidation.xlsx`;
      XLSX.utils.book_append_sheet(workbook, worksheet, "getAssitantInvoicingValidation");
      XLSX.writeFile(workbook, sheetName);
    }

    private async getActiveDbFromDate(){
      let cpt = 0;
      const listOfGroups = this.localSettingsService.listOfGroups?.length ? this.localSettingsService.listOfGroups : await this.groupService.getAllGroups();
      const activeGroupsResp = await this.desktopService.listGroups();
      const activeGroups = activeGroupsResp?.status?.status === 200 ? activeGroupsResp?.content : [];
      const inactiveGroups = listOfGroups?.filter(group => !activeGroups?.includes(group.id!!));
      const logs: any[] = [];
      for(let group of inactiveGroups){
        cpt++;
        const accessLogs: AccessLog[] = await this.accessLogService.getAccessLogByGroupId(group?.id!!, 600, `${new Date().getFullYear()}-01-01`, `${new Date().getFullYear()}-12-31`);
        const sortedAccessLogs : AccessLog[] = accessLogs?.sort((a, b) => b?.modified!! - a?.modified!!);
          const lastLog: AccessLog = sortedAccessLogs?.[0];
          logs.push({
          groupId: group?.id,
          created: lastLog?.created ? `${new Date(lastLog?.created).getDate().toString()}/${(new Date(lastLog?.created).getMonth() + 1).toString()}/${new Date(lastLog?.created).getFullYear().toString()}`: '-',
          modified: lastLog?.modified ? `${new Date(lastLog?.modified).getDate().toString()}/${(new Date(lastLog?.modified).getMonth() + 1).toString()}/${new Date(lastLog?.modified).getFullYear().toString()}` : '-',
          date: lastLog?.date ? `${new Date(lastLog?.date).getDate().toString()}/${(new Date(lastLog?.date).getMonth() + 1).toString()}/${new Date(lastLog?.date).getFullYear().toString()}` : '-',
          accessType: lastLog?.accessType || '-'
        })
        console.log(cpt+'/'+inactiveGroups.length);
      }

        var worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(logs);
        const workbook = XLSX.utils.book_new();
        const sheetName: string = `${new Date().getTime().toString()}_getActiveDbFromDate.xlsx`;
        XLSX.utils.book_append_sheet(workbook, worksheet, "getActiveDbFromDate");
        XLSX.writeFile(workbook, sheetName);
    }
}
