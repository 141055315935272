<div>
  <div class="oAuthApi">
    <div class="signin-btn-google" (click)="openOAuthApi()">
      <div class="icon google">
        <div class="icon-google xs"></div>
      </div>
    </div>
  </div>
  <mat-expansion-panel class="mt10">
    <mat-expansion-panel-header>
      Autres options de connexion
    </mat-expansion-panel-header>
    <div class="classic">
      <mat-form-field class="full-width">
        <mat-label>Login</mat-label>
        <input type="email" matInput [(ngModel)]="credentials.username">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input type="password" matInput [(ngModel)]="credentials.password">
      </mat-form-field>
    </div>
    <div class="buttons">
      <button mat-flat-button color="primary" (click)="login()">Connexion</button>
    </div>
  </mat-expansion-panel>
  <div class="buttons">
  </div>
</div>

