import {ApiService} from "./api.service";
import {Injectable} from "@angular/core";
import {DesktopApiCredentials} from "../models/desktop.model";
import {FetchService} from "./fetch.service";
import {FetchResponse} from "./couch-db.service";
import {LocalSettingsService} from "./local-settings.service";

@Injectable({
  providedIn: 'root',
})
export class WarmupService {
  constructor(
    private api: ApiService,
    private fetchService: FetchService,
    private localStorageService: LocalSettingsService,
  ) {
  }

  public async updateDesignDoc(entity: string, groupId: string, host: string): Promise<FetchResponse>{
    const resp = await this.fetchService.executeFetchRequest(`${host}/rest/v1/group/${groupId}/dd?${entity}&warmup=true`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: 'Basic '+this.localStorageService.basicAuth,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })

    return {
      status: {
        status:  resp?.response?.status,
        statusText:  resp?.response?.statusText,
      },
      content: resp?.content
    };
  }

  public getEntitiesToWarmUp(){
    return [
      { "entity": "AccessLog" },
      { "entity": "Patient" },
      { "entity": "Agenda" },
      { "entity": "Article" },
      { "entity": "CalendarItem" },
      { "entity": "CalendarItemType" },
      { "entity": "Classification" },
      { "entity": "Contact" },
      { "entity": "Document" },
      { "entity": "EntityReference" },
      { "entity": "EntityTemplate" },
      { "entity": "Form" },
      { "entity": "HealthElement" },
      { "entity": "Invoice" },
      { "entity": "Message" },
      { "entity": "Place" },
      { "entity": "Receipt" },
      { "entity": "TimeTable" },
      { "entity": "ApplicationSettings" },
      { "entity": "ClassificationTemplate" },
      { "entity": "Code" },
      { "entity": "DocumentTemplate" },
      { "entity": "FormTemplate" },
      { "entity": "FrontEndMigration" },
      { "entity": "HealthcareParty" },
      { "entity": "Insurance" },
      { "entity": "Keyword" },
      { "entity": "MaintenanceTask" },
      { "entity": "MedicalLocation" },
      { "entity": "Tarification" },
      { "entity": "User" }
    ]
  }
}
