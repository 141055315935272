import {Injectable} from "@angular/core";
import {User} from "@icure/api";
import {VersionType} from "./access-matrix.service";
import {AuthenticationService} from "./authentication.service";
import {ApiService} from "./api.service";
import {LocalSettingsState} from "./local-settings.state";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private auth: AuthenticationService,
    private api: ApiService,
    private localSettings: LocalSettingsState,
    private router: Router
  ) {
  }

  public redirectConnectedUser(user: User, path: string = 'private'){
    return this.router.navigate([path]).catch(err => {
      console.warn('Cannot redirect due to', err);
    })
  }
}
