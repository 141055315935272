import {Component, Input, ViewChild} from '@angular/core';
import {ApplicationSettings} from "@icure/api";
import {MatTableDataSource} from "@angular/material/table";
import {MatSortable} from "@angular/material/sort";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";

interface FormatedAppSettingsData{
  id: string;
  settings: string;
  //settings: { [key: string]: string; };
}

@Component({
  selector: 'ms-database-group-info-app-settings',
  templateUrl: './database-group-info-app-settings.component.html',
  styleUrls: ['./database-group-info-app-settings.component.scss']
})
export class DatabaseGroupInfoAppSettingsComponent {

  // @ts-ignore
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ts-ignore
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() appSettings: Array<ApplicationSettings> = [];

  public displayedColumns: string[] = ['id', 'settings'];
  // @ts-ignore
  public dataSource: MatTableDataSource;
  public formattedAppSettingsDatas: Array<FormatedAppSettingsData> = [];
  public selectedFilter: string = '';
  constructor() {
  }

  ngOnInit(){
    this.sort.sort({id: '_id', start: 'asc'} as MatSortable);
    this.formattedAppSettingsDatas = this.createDataSource(this.appSettings);
    this.dataSource = new MatTableDataSource(this.formattedAppSettingsDatas);
    this.dataSource.sort = this.sort;
    /*this.dataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        default: {
          return item[property];
        }
      }
    };*/
    //this.filterChanged();
  }

  private createDataSource(appSettings: Array<ApplicationSettings>): Array<FormatedAppSettingsData>{
    const dataSource: Array<FormatedAppSettingsData> = [];
    appSettings?.map((appSetting: any) => {
        dataSource.push({
          id: appSetting?._id!!,
          settings: JSON.stringify(appSetting.settings),
        })
    })
    return dataSource;
  }

  public filterChanged(){
    let filteredAppSettings: Array<FormatedAppSettingsData> = [];

    this.dataSource.data = filteredAppSettings;

    if(this.selectedFilter){
      const searchFilter = this.selectedFilter;
      this.dataSource.filter = searchFilter;
      if (searchFilter.length > 2) {
        this.dataSource.filter = searchFilter;
      }else{
        this.dataSource.filter = "";
      }
    }else{
      this.dataSource.filter = "";
    }

  }

}
