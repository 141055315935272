<div *ngIf="isLoading"
     [ngClass]="{
        'full-width': fullWidth,
        overlayBackground: overlay
    }">
  <div class="inner">
    <div class="textaligncenter mb10">
      <mat-spinner class="spinner"></mat-spinner>
      Warm up du groupe: {{ groupId }}
    </div>
    <div class="entities">
      <div class="entity" *ngFor="let item of messages">
        <mat-icon class="fas ic-status text-center green icon" *ngIf="item.isDone">check_circle</mat-icon>
        <mat-icon class="fas ic-status text-center icon" *ngIf="!item.isDone">arrow_forward</mat-icon>
        <div>{{ item.message }}</div>
      </div>
    </div>
  </div>
</div>
