import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Address, HealthcareParty, User} from "@icure/api";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {KEYS_PROP, LICENCE_KEY} from "../../database-user/database-user.component";
import {UserService} from "../../../../../lib/services/user.service";
import {VersionType} from "../../../../../lib/services/access-matrix.service";
import {LicenceInfos, UserInfo} from "../../../../../lib/models/user.model";
import {HardwareInfoDialogComponent} from "../dialogs/hardware-info-dialog/hardware-info-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {UserDialogComponent} from "../dialogs/user-dialog/user-dialog.component";
import {HcpService} from "../../../../../lib/services/hcp.service";

interface FormatedUserDatas{
  userId: string;
  firstName: string;
  lastName: string;
  name: string;
  parentId: string | null;
  creationDate: Date | null;
  creationDateHr: string;
  login: string;
  ssin: string;
  nihii: string;
  isKeyGenerate: boolean;
  adr: {
    street: string;
    houseNumber: string;
    city: string;
    country: string;
    postalCode: string;
  },
  speciality: string;
  steps: {
    step_1: boolean;
    step_2: boolean;
    step_3: boolean;
    step_4: boolean;
    step_5: boolean;
  },
  status: string;
  freeStatusHr: string;
  statusHr: string;
  licence?: LicenceInfos;
}

@Component({
  selector: 'ms-database-group-info-user',
  templateUrl: './database-group-info-user.component.html',
  styleUrls: ['./database-group-info-user.component.scss']
})
export class DatabaseGroupInfoUserComponent implements OnInit{

  // @ts-ignore
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  // @ts-ignore
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input() listOfUsers: Array<User> = [];
  @Input() listOfHcps: Array<HealthcareParty> = [];
  @Input() versionType: VersionType = VersionType.FULL;
  @Input() groupInfos: any = {};

  public displayedColumns: string[] = [];
  public fullColumns: string[] = ['creationDateHr', 'name', 'login', 'nihii', 'speciality', 'ssin', 'street', 'postalCode', 'city', 'country', 'licenceStartDate', 'licenceEndDate', 'statusHr'];
  public freeColumns: string[] = ['creationDateHr', 'name', 'login', 'nihii', 'speciality', 'ssin', 'street', 'postalCode', 'city', 'country', 'steps', 'freeStatusHr'];
  // @ts-ignore
  public dataSource: MatTableDataSource;
  public formattedUserData: FormatedUserDatas[] = [];
  public inactiveUser: boolean = false;
  public activeUser: boolean = true;
  public selectedFilter: string = '';

  constructor(
    private userService: UserService,
    private hcpService: HcpService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(){
    this.initialize();
  }

  private initialize(){
    this.displayedColumns = this.versionType === VersionType.FREE ? this.freeColumns : this.fullColumns;
    this.sort.sort({id: 'name', start: 'asc'} as MatSortable);
    this.formattedUserData = this.createDataSource(this.listOfUsers, this.listOfHcps);
    this.dataSource = new MatTableDataSource(this.formattedUserData);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'creationDateHr': {
          let newDate = item?.creationDate;
          return newDate;
        }
        default: {
          return item[property];
        }
      }
    };
    this.filterChanged();
  }

  private createDataSource(listOfUsers: Array<User>, listOfHcps: Array<HealthcareParty>): Array<FormatedUserDatas>{
    const dataSource: Array<FormatedUserDatas> = [];
    listOfUsers?.map((user: User) => {
      if(!user?.patientId){
        const hcp: HealthcareParty = listOfHcps?.find(hcp => hcp?.id === user?.healthcarePartyId) || {};
        const hcpAdr: Address = hcp?.addresses?.find(adr => adr?.addressType === Address.AddressTypeEnum.Work) || {};
        const creationDate: Date | null = user?.createdDate ? new Date(user?.createdDate)!! : null;
        const hasKeys: boolean = hcp?.hcPartyKeys ? !!Object?.keys(hcp?.hcPartyKeys as object)?.length : false;
        const licenceInfos = this.userService.getLicenceInfos(user?.properties?.find(prop => prop?.type?.identifier === LICENCE_KEY)!!);

        dataSource.push({
          userId: user.id || '',
          firstName: hcp?.firstName!!,
          lastName: hcp?.lastName!!,
          parentId: hcp?.parentId || '',
          name: hcp?.lastName || hcp?.firstName ? `${hcp?.lastName!!} ${hcp?.firstName!!}` : hcp?.name!!,
          creationDate: creationDate,
          creationDateHr: creationDate ? `${creationDate.getDate().toString().padStart(2, '0')}/${(creationDate.getMonth() +1).toString().padStart(2, '0')}/${creationDate.getFullYear()}` : '-',
          login: user.login || '',
          ssin: hcp?.ssin || '',
          nihii: hcp?.nihii || hcp?.options?.['inami'] ||  '',
          isKeyGenerate: user?.properties?.find(prop => prop?.type?.identifier === KEYS_PROP)?.typedValue?.booleanValue || false,
          adr: {
            street: hcpAdr?.street || "",
            houseNumber: hcpAdr?.houseNumber || "",
            city: hcpAdr?.city || "",
            country: hcpAdr?.country || "",
            postalCode: hcpAdr?.postalCode || ""
          },
          speciality: this.userService.getUserSpeciality(hcp?.nihii || hcp?.options?.['inami']!!),
          steps: {
            step_1: !!user?.passwordHash,
            step_2: !!hcp?.nihii,
            step_3: !!hcpAdr?.postalCode,
            step_4: !!hcp?.bankAccount,
            step_5: hasKeys || false,
          },
          freeStatusHr: !user?.passwordHash ? 'Inactif' : hasKeys ? 'Actif' : !!user?.passwordHash && !hasKeys ? 'Bloqué' : '',
          status: user?.status!!,
          statusHr: user?.status === 'ACTIVE' ? 'Actif' : 'Désactivé',
          licence: {
            startDate: licenceInfos?.startDate || '-',
            endDate: licenceInfos?.endDate || '-',
            licenceType: licenceInfos?.licenceType ||'-'
          }
        })
      }
    })

    return dataSource;
  }

  public filterChanged(){
    let filteredUser: Array<FormatedUserDatas> = [];

    this.activeUser ? filteredUser = filteredUser.concat(this.formattedUserData?.filter((user: FormatedUserDatas) => user?.status === User.StatusEnum.ACTIVE)) : null;
    this.inactiveUser ? filteredUser = filteredUser.concat(this.formattedUserData?.filter((user: FormatedUserDatas) => user?.status === User.StatusEnum.DISABLED)) : null;
    !this.activeUser && !this.inactiveUser ? filteredUser = filteredUser.concat(this.formattedUserData) : null;

    this.dataSource.data = filteredUser;

    if(this.selectedFilter){
      const searchFilter = this.selectedFilter;
      this.dataSource.filter = searchFilter;
      if (searchFilter.length > 2) {
        this.dataSource.filter = searchFilter;
      }else{
        this.dataSource.filter = "";
      }
    }else{
      this.dataSource.filter = "";
    }

  }

  public async openUserCreationDialog() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      data: {groupInfos: this.groupInfos, listOfHcps: this.listOfHcps},
      height: '700px',
      width: '1000px'
    });

    dialogRef.componentInstance.onCreatedUser.subscribe(async (data: any) => {
      if(data?.status === 'success'){
        this.listOfUsers = await this.userService.getUserInGroup(this.groupInfos.id);
        this.listOfHcps = await this.hcpService.getHcpsInGroup(this.groupInfos.id, this.listOfUsers?.filter(user => !user?.patientId)?.map(usr => usr?.healthcarePartyId!!));
        this.initialize();
      }
    });
  }
}
