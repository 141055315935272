import { NgModule } from '@angular/core';
import {RouterModule, Routes, UrlSegment} from "@angular/router";
import {HomeComponent} from "./home/home.component";
import {PrivateHomeComponent} from "./private/private-home/private-home.component";
import {OAuthLoginComponent} from "./login/o-auth-login/o-auth-login.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'private',
    component: PrivateHomeComponent,
    loadChildren: () => import('./private/private.module').then(module => module.PrivateModule),
  },
  {
    path: 'oAuthLogin',
    component: OAuthLoginComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
