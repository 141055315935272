<h5 mat-dialog-title>{{selectedConfig?.configId}}</h5>
<div class="dialog-container">
  <div class="dialog-container-content">
    <div class="dialog-container-content-info mb10">
      <div class="section">Informations</div>
      <div class="table">
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Hostname:</span>
          </div>
          <div class="td w50">
            <span class="fwb">Mac adresse:</span> {{selectedConfig?.macAdresses}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">IPV4:</span> {{selectedConfig?.ipV4Addresses?.join(',')}}
          </div>
          <div class="td w50">
            <span class="fwb">IPV6:</span> {{selectedConfig?.ipV6Addresses?.join(',')}}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-container-content-hardware mb10">
      <div class="section">Informations hardware</div>
      <div class="table">
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Date maj:</span> {{getUpdateDate(selectedConfig?.updatedAt)}}
          </div>
          <div class="td w50">
            <span class="fwb">Version OS:</span> {{selectedConfig?.osVersion}}
          </div>
        </div>
        <div class="tr">
          <div class="td w100">
            <span class="fwb">Modèle du CPU:</span> {{selectedConfig?.cpuModel}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Nombre de coeur:</span> {{selectedConfig?.cpuCount}}
          </div>
          <div class="td w50">
            <span class="fwb">Qt de RAM:</span> {{getBytes(selectedConfig?.ramQty)}}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-container-content-storage mb10">
      <div class="section">Informations sur l'espace de stockage</div>
      <div class="table">
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Espace disque total:</span> {{getBytes(selectedConfig?.diskSize)}}
          </div>
          <div class="td w50">
            <span class="fwb">Espace disque libre:</span> {{getBytes(selectedConfig?.diskSapceFree)}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Espace donnée couch db:</span> {{getBytes(selectedConfig?.couchDataSpace)}}
          </div>
          <div class="td w50">
            <span class="fwb">Espace couch db vues:</span> {{getBytes(selectedConfig?.couchViewSpace)}}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-container-content-version mb10">
      <div class="section">Informations sur la version</div>
      <div class="table">
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Version actuelle:</span> {{selectedConfig?.currentVersion}}
          </div>
          <div class="td w50">
            <span class="fwb">Version couchdb:</span> {{selectedConfig?.couchDBVersion}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Version electron:</span> {{selectedConfig?.electronVersion}}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-container-content-installation mb10">
      <div class="section">Informations sur la configuration</div>
      <div class="table">
        <div class="tr">
          <div class="td w50">
            <span class="fwb">mainUrl:</span> {{selectedConfig?.mainUrl}}
          </div>
          <div class="td w50">
            <span class="fwb">setupUrl:</span> {{selectedConfig?.setupUrl}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Mode de réplication:</span> {{selectedConfig?.replicationMode}}
          </div>
          <div class="td w50">
            <span class="fwb">Type d'installation:</span> {{selectedConfig?.installationType}}
          </div>
        </div>
        <div class="tr">
          <div class="td w50">
            <span class="fwb">Chemin du disque:</span> {{selectedConfig?.diskPath}}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-container-content-other mb10">
      <div class="section">Autres infos</div>
      <div class="table">
        <div class="tr">
          <div class="td w33">
            <span class="fwb">Adresse icure:</span> {{selectedConfig?.icureAddress}}
          </div>
          <div class="td w33">
            <span class="fwb">Serveur proxy:</span> {{selectedConfig?.proxyServer}}
          </div>
          <div class="td w33">
            <span class="fwb">Remote couch db:</span> {{selectedConfig?.remoteCouchDB}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-container-button">
    <button mat-button (click)="closeDialog()">Fermer</button>
  </div>
</div>

