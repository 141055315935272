export const DESKTOP_API_CREDENTIALS_PROP = 'be.medispring.desktopApi';

export interface UserInfo{
  groupId: string;
  groupPassword: string;
  userId: string;
  creationDate?: Date;
  creationDateHr?: string;
  name?: string;
  login?: string;
  isKeyGenerate?: boolean;
  nihii?: string;
  ssin?: string;
  adr?: {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
  },
  steps?: {
    step_1: boolean;
    step_2: boolean;
    step_3: boolean;
    step_4: boolean;
    step_5: boolean;
  }
  speciality?: string;
  statusHr?: string;
  userName?: string;
}

export interface LicenceInfos{
  startDate: string;
  endDate: string;
  licenceType: string;
}

export interface AssistantInfos{
  validationDate: string;
  isNextStructureTheSame: boolean;
  nextStatus: string;
  nextSupervisorId: string;
}
