<div>
  <div class="title">
    Reporting
  </div>
  <div>
    <table mat-table matSort [dataSource]="reportingList">
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de création</th>
        <td mat-cell *matCellDef="let element">{{element.creationDate}}</td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Titre</th>
        <td mat-cell *matCellDef="let element">{{element.title}}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element">{{element.description}}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="primary" (click)="launchReporting(element)">Lancer</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<ms-activity-indicator-with-messages [fullWidth]="true" [isLoading]="isLoading" [overlay]="true" [messages]="messages"></ms-activity-indicator-with-messages>
