<mat-toolbar id="searchBar">
  <mat-form-field>
    <mat-label>Année</mat-label>
    <mat-select [(value)]="selectedYear" required>
      <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <button mat-flat-button color="primary" (click)="initialize()">Récupérer les logs</button>
</mat-toolbar>
<table mat-table matSort [dataSource]="dataSource" *ngIf="!isLoading">
    <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let element">{{element?.firstName}}</td>
    </ng-container>
    <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Prénom</th>
        <td mat-cell *matCellDef="let element">{{element?.lastName}}</td>
    </ng-container>
    <ng-container matColumnDef="nihii">
        <th mat-header-cell *matHeaderCellDef>Inami</th>
        <td mat-cell *matCellDef="let element">{{element?.nihii}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_01">
        <th mat-header-cell *matHeaderCellDef>Jan</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_01}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_02">
        <th mat-header-cell *matHeaderCellDef>Fév</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_02}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_03">
        <th mat-header-cell *matHeaderCellDef>Mar</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_03}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_04">
        <th mat-header-cell *matHeaderCellDef>Avr</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_04}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_05">
        <th mat-header-cell *matHeaderCellDef>Mai</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_05}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_06">
        <th mat-header-cell *matHeaderCellDef>Jui</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_06}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_07">
        <th mat-header-cell *matHeaderCellDef>Jui</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_07}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_08">
        <th mat-header-cell *matHeaderCellDef>Aou</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_08}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_09">
        <th mat-header-cell *matHeaderCellDef>Sep</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_09}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_10">
        <th mat-header-cell *matHeaderCellDef>Oct</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_10}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_11">
        <th mat-header-cell *matHeaderCellDef>Nov</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_11}}</td>
    </ng-container>
    <ng-container matColumnDef="numberOfConnection_12">
        <th mat-header-cell *matHeaderCellDef>Déc</th>
        <td mat-cell *matCellDef="let element">{{element?.numberOfConnection_12}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="isLoading">
  <mat-spinner class="spinner"></mat-spinner>
</div>
