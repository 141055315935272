<mat-nav-list class="side-nav">
  <ng-container *ngFor="let element of menuElements">
    <ng-container [ngTemplateOutlet]="element.children ? childMenu : parentMenu" [ngTemplateOutletContext]="{element: element}">
      <ng-template #parentMenu let-element="element">
        <mat-list-item [routerLink]="element.routerLink" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-button">
            <mat-icon>{{element.icon}}</mat-icon>&nbsp;<span>{{element.title}}</span>
          </div>
        </mat-list-item>
      </ng-template>
      <ng-template #childMenu let-element="element">
        <mat-expansion-panel [class.mat-elevation-z0]="true">
          <mat-expansion-panel-header>
            <div class="menu-button">
              <mat-icon mat-list-icon>{{element.icon}}</mat-icon><span>{{element.title}}</span>
            </div>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <mat-list-item [routerLink]="submenu.routerLink" *ngFor="let submenu of element.children" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" [state]="element.state">
              <div class="menu-button">
                <mat-icon>{{submenu.icon}}</mat-icon>&nbsp;<span>{{submenu.title}}</span>
              </div>
            </mat-list-item>
          </mat-nav-list>
        </mat-expansion-panel>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-nav-list>
