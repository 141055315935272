import {Component, Injectable, Input} from '@angular/core';

export interface ActivityIndicatorWithMessages {
  id?: string;
  message: string;
  isDone?: boolean;
}

@Component({
  selector: 'ms-activity-indicator-with-messages',
  templateUrl: './activity-indicator-with-messages.component.html',
  styleUrls: ['./activity-indicator-with-messages.component.scss']
})
@Injectable({
  providedIn: 'root',
})
export class ActivityIndicatorWithMessagesComponent {
  @Input() isLoading: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() overlay: boolean = true;
  @Input() persistInformation: boolean = false;
  @Input() messages: ActivityIndicatorWithMessages[] = [];

  public stopSpinnerLoading: boolean = false;

  public add(textMessage: string): void {
    if (!textMessage.trim()) return;
    this.isLoading = true;

    const lastMessage = this.messages.pop();
    if (lastMessage) this.messages.push({ message: lastMessage.message, isDone: true });
    this.addMessage(textMessage);
  }

  public update(textMessage: string): void {
    if (!textMessage.trim()) return;
    this.messages.pop() && this.addMessage(textMessage);
  }

  private addMessage(textMessage?: string): void {
    this.messages.push({
      message: (textMessage || '').trim(),
    });
  }

  public done(): void {
    if (this.persistInformation) {
      this.stopSpinnerLoading = true;
      const lastMessage = this.messages.pop();
      if (lastMessage) this.messages.push({ message: lastMessage.message, isDone: true });
    } else {
      this.isLoading = false;
      this.reset();
    }
  }

  public reset(): void {
    this.messages = [];
  }

}
