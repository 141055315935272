import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ms-desactivation-config-dialog',
  templateUrl: './desactivation-config-dialog.component.html',
  styleUrls: ['./desactivation-config-dialog.component.scss']
})
export class DesactivationConfigDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DesactivationConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  public confirmDesactivation(){
    this.dialogRef.close(this.data.configId);
  }

  public closeDialog(){
    this.dialogRef.close(null);
  }
}
