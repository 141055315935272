import {Component, Inject, Input, OnInit} from '@angular/core';
import {GroupInfo} from "../../../../../lib/models/group.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ms-release-selector-dialog',
  templateUrl: './release-selector-dialog.component.html',
  styleUrls: ['./release-selector-dialog.component.scss']
})
export class ReleaseSelectorDialogComponent implements OnInit{

  public selectedVersion: string = '';

  constructor(
    public dialogRef: MatDialogRef<ReleaseSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.selectedVersion = this.data.availableVersions?.[this.data.availableVersions.length - 1];
  }

  public confirmVersion(){
    this.dialogRef.close(this.selectedVersion);
  }

  public closeDialog(){
    this.dialogRef.close(null);
  }

}
