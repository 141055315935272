import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class FetchService {
  constructor() {
  }

  public async executeFetchRequest(url: string, data: any, getAs?: string): Promise<any>{
    const fetchImpl = typeof window !== 'undefined' ? window.fetch : typeof self !== 'undefined' ? self.fetch : fetch;
    return fetchImpl(url, data)
      .then((response: any) =>
        Promise.all([response, getAs === 'application/octet-stream' ? this.blobToArrayBuffer(response) : response.text()])
      )
      .then(([res, content]) =>
        Promise.resolve({
          response: res,
          content: this.isJson(content) ? JSON.parse(content) : content,
        })
      )
      .catch(err => console.log(err));
  }

  private isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    const { reader, promise } = this.fileReaderAndPromise();
    reader.readAsArrayBuffer(blob);
    return promise as Promise<ArrayBuffer>;
  }

  private fileReaderAndPromise(){
    const reader = new FileReader();
    const promise = new Promise<string | ArrayBuffer>((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result!!);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.onabort = () => {
        reject(null);
      };
    });

    return { reader, promise };
  }
}
