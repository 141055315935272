import {Component, OnInit} from '@angular/core';
import { version } from '../../../version';

@Component({
  selector: 'ms-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit{
  public version: string = version;

  constructor() {
  }

  ngOnInit() {
  }
}
