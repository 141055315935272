import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {humanReadableBytes} from "../../../../../../lib/utils/function-utils";
import {padStart} from "lodash";


@Component({
  selector: 'ms-hardware-info-dialog',
  templateUrl: './hardware-info-dialog.component.html',
  styleUrls: ['./hardware-info-dialog.component.scss']
})
export class HardwareInfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<HardwareInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public selectedConfig: any,
  ) {}

  public closeDialog(){
    this.dialogRef.close();
  }

  public getUpdateDate(dateAsNumber: number): string{
    const date = new Date(dateAsNumber);
    return padStart(date.getDay().toString(), 2, '0')+'/'+padStart(date.getMonth().toString(), 2, '0')+'/'+date.getFullYear();
  }

  public getBytes(bytes: number): string{
    return humanReadableBytes(bytes);
  }
}
