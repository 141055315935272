import {Component, OnInit} from '@angular/core';
import {ReportingInfo} from "../../../lib/models/reporting.model";
import {ReportingService} from "../../../lib/services/reporting.service";
import {UserService} from "../../../lib/services/user.service";
import {
  ActivityIndicatorWithMessages
} from "../../../lib/activity-indicator-with-messages/activity-indicator-with-messages.component";

@Component({
  selector: 'ms-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit{

  public isLoading: boolean = false;
  public messages: Array<ActivityIndicatorWithMessages> = [];

  private availableReporting: Array<ReportingInfo> = [
    {
      name: 'getAllUsersByGroupId',
      creationDate: '2023-04-22',
      title: 'Liste des utilisateurs',
      description: "Liste l'ensemble des utilisateurs actifs par groupeId",
      isActive: true
    },
    {
      name: 'getLicenceOfAllUsersByGroupId',
      creationDate: '2023-04-27',
      title: 'Liste des licences',
      description: "Liste l'ensemble des utilisateurs actifs avec leur licence",
      isActive: true
    },
    {
      name: 'getActiveDBFromDate',
      creationDate: '2023-12-04',
      title: 'Liste des db actives',
      description: "Liste l'ensemble des db et indique s'il y a eu une connexion dans l'année",
      isActive: true
    },
    {
      name: 'getAssitantInvoicingValidation',
      creationDate: '2024-08-12',
      title: 'Liste des assitants',
      description: "Récupère les données de facturation pour les assistants",
      isActive: true
    },
    {
      name: 'getDatabaseOriginSetting',
      creationDate: '2024-08-25',
      title: 'Liste des bdd avec le tag database origin',
      description: "Liste l'ensemble des bdd avec le tag database origin",
      isActive: true
    }
  ];

  public reportingList: Array<ReportingInfo> = [];
  public displayedColumns: string[] = ['creationDate', 'title', 'description', 'action'];

  constructor(
    private reportingService: ReportingService,
  ) {

  }

  ngOnInit() {
    this.reportingList = this.availableReporting?.filter(reporting => reporting.isActive);
  }

  public async launchReporting(reportingInfo: ReportingInfo){
    this.messages.push({id: reportingInfo.name, isDone: false, message: 'Génération du rapport en cours, cela peut prendre quelques minutes...'});
    this.isLoading = true;
    await this.reportingService.launchReporting(reportingInfo);
    this.isLoading = false;
  }
}
