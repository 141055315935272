import {Component} from '@angular/core';
import {AuthenticationService} from "../../../lib/services/authentication.service";
import {Router} from "@angular/router";
import {Group, User} from "@icure/api";
import {UserInfo} from "../../../lib/models/user.model";
import {LocalSettingsService} from "../../../lib/services/local-settings.service";
import {VersionType} from "../../../lib/services/access-matrix.service";
import {ApiService} from "../../../lib/services/api.service";

@Component({
  selector: 'ms-private-home',
  templateUrl: './private-home.component.html',
  styleUrls: ['./private-home.component.scss']
})
export class PrivateHomeComponent {

  public currentUser: User = {};
  public userInfos: Array<UserInfo> = [];
  public isLoading: boolean = false;
  public allGroups: Array<Group> = [];
  public isFreeVersion: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private localSettingsService: LocalSettingsService,
    private api: ApiService
  ) {}

  async ngOnInit() {
    try {
      this.currentUser = await this.auth.getCurrentUser()
      if(this.currentUser?.id){
        this.isFreeVersion = this.localSettingsService.version === VersionType.FREE;
      }else{
        this.router.navigate(['/']);
      }
    }catch (e) {
      console.warn(e)
      this.router.navigate(['/']);
    }
  }

  public async logout(){
    await this.auth.logout();
  }

  /*public async updateUser(){
    this.api.userApi?.modifyProperties(this.currentUser.id!!, [{
      type: {
        identifier: "be.medispring.desktopApi"
      },
      typedValue: {
        type: "STRING",
        stringValue: '********'
      }
    }])
  }*/
}
