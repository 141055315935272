import * as CryptoJS from 'crypto-js';
import * as msConfig from "../../../msconfig.json";
import {Injectable} from "@angular/core";

const VALIDATOR = 'shibboleth';

@Injectable({
  providedIn: 'root',
})
export class CryptoService{
  constructor() {
  }

  public encrypt(data: string, key: string): string {
    return CryptoJS.AES.encrypt(data, key).toString();
  }

  public decrypt(data: string, key: string):string {
    return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
  }

  public isValidKey(key: string): boolean {
    return this.decrypt(msConfig?.validator?.key, key) === VALIDATOR;
  }
}
