<div id="groupsTable">
  <div class="table-container">
    <mat-toolbar id="searchBar">
      <div class="searchInput">
        <mat-form-field class="full-width">
          <mat-label>Recherche</mat-label>
          <input matInput [(ngModel)]="selectedFilter" (keyup)="filterChanged()">
        </mat-form-field>
      </div>
      <div class="extraInput" *ngIf="accessMatrixService?.isAvailableModule(RouteTypeEnum.DATABASE, ModuleTypeEnum.DATABASE_GROUP, SubModuleTypeEnum.DATABASE_GROUP_INFO_HARDWARE)">
        <section>
          <mat-checkbox labelPosition="after" [(ngModel)]="krakenUser" (click)="filterChanged()">
            Kraken
          </mat-checkbox>
        </section>
      </div>
    </mat-toolbar>
    <table mat-table matSort [dataSource]="groupInfosDataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="groupId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Id du groupe</th>
        <td mat-cell *matCellDef="let element">{{element.groupId}}</td>
      </ng-container>
      <ng-container matColumnDef="servers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serveur</th>
        <td mat-cell *matCellDef="let element">{{element.servers}}</td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
        <td mat-cell *matCellDef="let element">
          <div class="b" *ngIf="element.isActive">
            <mat-icon>check_box</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kraken</th>
        <td mat-cell *matCellDef="let element">
          <div class="b" *ngIf="element.isKrakenVersion">
            <mat-icon>check_box</mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="authorizedVersion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Version(s) activée(s)</th>
        <td mat-cell *matCellDef="let element">
          <div *ngFor="let el of element?.releaseVersion">
            <div *ngIf="el?.authorization !== UpdateTypeEnum.NOT_ALLOWED">
              {{el?.version}}: <span *ngIf="el?.authorization === UpdateTypeEnum.ALLOWED">Activée</span> <span *ngIf="el?.authorization === UpdateTypeEnum.PARTIALLY_ALLOWED">Partiellement activée</span>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="btn-flex-container">
            <button mat-icon-button title="Informations du groupe" [routerLink]="['group-infos', element.groupId]">
              <mat-icon>info</mat-icon>
            </button>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!--<button mat-icon-button (click)="connectAndRedirect(element)" disabled title="Coming soon">
                <mat-icon>open_in_new</mat-icon> Ouvrir fauxton
              </button>-->
              <button mat-menu-item *ngIf="!element.isKrakenVersion" (click)="openVersionSelector(element, releaseActionEnum.ADD)">
                <mat-icon>add_circle</mat-icon>
                <span>Activer le groupe</span>
              </button>
              <button mat-menu-item *ngIf="element.isKrakenVersion" (click)="openVersionSelector(element, releaseActionEnum.REMOVE)">
                <mat-icon>remove_circle</mat-icon>
                <span>Désactiver le groupe</span>
              </button>
              <!--<button mat-menu-item *ngIf="element.isKrakenVersion" (click)="activeWarmUp(element)">
                <mat-icon>view_comfy</mat-icon>
                <span>Lancer le warm-up</span>
              </button>-->
            </mat-menu>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
  <ms-activity-indicator-with-messages [fullWidth]="true" [isLoading]="isLoading" [overlay]="true" [messages]="messages"></ms-activity-indicator-with-messages>
  <ms-activity-indicator-warmup [fullWidth]="true" [isLoading]="isWarmUp" [overlay]="true" [messages]="warmUpMessages" [groupId]="selectedGroupId"></ms-activity-indicator-warmup>
</div>
