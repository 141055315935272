import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";

export enum ViewActions {
  DATABASE_USERS = 'users',
  DATABASE_GROUPS = 'groups',
}

@Component({
  selector: 'ms-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit{

  // @ts-ignore
  public action = ViewActions.DATABASE_GROUPS;
  public dataBaseViewActions = ViewActions;

  constructor(
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    return this.route.paramMap.subscribe(params => this.routeChanged(params));
  }

  private async routeChanged(params: ParamMap) {
    this.action = ViewActions.DATABASE_GROUPS;
    const originalAction = <ViewActions>params.get('node') || ViewActions.DATABASE_GROUPS;
    setTimeout(() => {
      this.action = originalAction;
    }, 200);
  }

}
