export enum PhysicianTypeEnum{
  GENERAL_PRACTITIONER = 'generalPractitioner',
  SPECIALIST = 'specialist',
  TRAINEE = 'trainee'
}

export interface PhysicianSpeciality{
  code: string;
  type: Array<PhysicianTypeEnum>;
  speciality: {
    fr: string;
    nl: string;
    en: string;
  }
}

export const physicianSpecialityList: Array<PhysicianSpeciality> = [
  {code: "000", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality : {"fr" : "porteur du diplôme de médecin, inscrit après le 31/ 12/ 2004", "nl" : "", "en": ""}},
  {code: "009", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality : {"fr" : "porteur du diplôme de médecin, inscrit entre le 01/ 01/ 1995 et le 31/ 12/ 2004", "nl" : "", "en": ""}},
  {code: "001", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecin généraliste avec droits acquis (inscrits jusqu’au 31/12/1994)", "nl" : "", "en": ""}},
  {code: "002", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecin généraliste avec droits acquis (inscrits jusqu’au 31/12/1994) + titulaire d’attestation ECG", "nl" : "", "en": ""}},
  {code: "003", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecin généraliste agréés", "nl" : "", "en": ""}},
  {code: "004", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecin généralistes agréé + titulaire d’attestation ECG", "nl" : "", "en": ""}},
  {code: "005", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin généraliste en formation", "nl" : "", "en": ""}},
  {code: "006", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin généraliste en formation + titulaire d’attestation ECG", "nl" : "", "en": ""}},
  {code: "007", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecins généraliste agrée avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "008", type : [PhysicianTypeEnum.GENERAL_PRACTITIONER], speciality: {"fr": "médecin généraliste agrée, porteur d'un certificat de compétence en ECG, avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "010", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en anesthésiologie - réanimation", "nl" : "", "en": ""}},
  {code: "014", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie", "nl" : "", "en": ""}},
  {code: "017", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en neurochirurgie", "nl" : "", "en": ""}},
  {code: "018", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en gériatrie", "nl" : "", "en": ""}},
  {code: "021", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie plastique", "nl" : "", "en": ""}},
  {code: "024", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie abdominale", "nl" : "", "en": ""}},
  {code: "027", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie thoracique", "nl" : "", "en": ""}},
  {code: "031", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie des vaisseaux", "nl" : "", "en": ""}},
  {code: "034", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en gynécologie-obstétrique", "nl" : "", "en": ""}},
  {code: "037", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en ophtalmologie", "nl" : "", "en": ""}},
  {code: "041", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en oto-rhino-laryngologie", "nl" : "", "en": ""}},
  {code: "045", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en urologie", "nl" : "", "en": ""}},
  {code: "048", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en chirurgie orthopédique", "nl" : "", "en": ""}},
  {code: "052", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en stomatologie", "nl" : "", "en": ""}},
  {code: "055", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en dermato-vénérologie", "nl" : "", "en": ""}},
  {code: "058", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en médecine interne", "nl" : "", "en": ""}},
  {code: "062", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en pneumologie", "nl" : "", "en": ""}},
  {code: "065", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en gastro-entérologie,", "nl" : "", "en": ""}},
  {code: "066", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en oncologie médicale", "nl" : "", "en": ""}},
  {code: "069", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en pédiatrie", "nl" : "", "en": ""}},
  {code: "073", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en cardiologie", "nl" : "", "en": ""}},
  {code: "076", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "077", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en neurologie", "nl" : "", "en": ""}},
  {code: "078", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en psychiatrie", "nl" : "", "en": ""}},
  {code: "079", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en rhumatologie", "nl" : "", "en": ""}},
  {code: "080", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en médecine aigüe", "nl" : "", "en": ""}},
  {code: "081", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en génétique clinique", "nl" : "", "en": ""}},
  {code: "083", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "086", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en biologie clinique", "nl" : "", "en": ""}},
  {code: "087", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en anatomie-pathologique", "nl" : "", "en": ""}},
  {code: "090", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "093", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en radiodiagnostic", "nl" : "", "en": ""}},
  {code: "096", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "097", type : [PhysicianTypeEnum.SPECIALIST, PhysicianTypeEnum.TRAINEE], speciality: {"fr": "médecin spécialiste en formation en médecine nucléaire", "nl" : "", "en": ""}},
  {code: "100", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésiologie - réanimation", "nl" : "", "en": ""}},
  {code: "101", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésiologie - réanimation avec médecine générale", "nl" : "", "en": ""}},
  {code: "109", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésie-réanimation, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "119", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésie-réanimation et psychiatrie, porteur du titre professionnel en médecine d'urgence", "nl" : "", "en": ""}},
  {code: "126", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésiologie - réanimation et en médecine interne", "nl" : "", "en": ""}},
  {code: "131", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésiologie - réanimation et en cardiologie", "nl" : "", "en": ""}},
  {code: "137", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anesthésiologie - réanimation et en biologie clinique", "nl" : "", "en": ""}},
  {code: "140", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie", "nl" : "", "en": ""}},
  {code: "145", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "medecin spécialiste en chirurgie et dermato-vénéréologie", "nl" : "", "en": ""}},
  {code: "146", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en gynécologie-obstétrique", "nl" : "", "en": ""}},
  {code: "149", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie, porteur du titre professionnel particulier en médecine d'urgence", "nl" : "", "en": ""}},
  {code: "151", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en chirurgie orthopédique", "nl" : "", "en": ""}},
  {code: "152", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en stomatologie", "nl" : "", "en": ""}},
  {code: "153", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie, avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "154", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique et en chirurgie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "157", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en pneumologie", "nl" : "", "en": ""}},
  {code: "158", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en gastro-entérologie,", "nl" : "", "en": ""}},
  {code: "162", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "166", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "170", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurochirurgie", "nl" : "", "en": ""}},
  {code: "174", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurochirurgie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "179", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurochirurgie, porteur du titre professionnel particulier en médecine d'urgence", "nl" : "", "en": ""}},
  {code: "180", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gériatrie", "nl" : "", "en": ""}},
  {code: "182", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gériatrie et médecine nucléaire", "nl" : "", "en": ""}},
  {code: "183", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gériatrie et endocrino-diabétologie", "nl" : "", "en": ""}},
  {code: "184", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gériatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "192", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurochirurgie et en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "195", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurochirurgie et en neuropsychiatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "210", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie plastique", "nl" : "", "en": ""}},
  {code: "219", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie plastique, porteur du titre professionnel particulier en médecine d'urgence", "nl" : "", "en": ""}},
  {code: "222", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie plastique et en stomatologie", "nl" : "", "en": ""}},
  {code: "240", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie abdominale", "nl" : "", "en": ""}},
  {code: "270", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie thoracique", "nl" : "", "en": ""}},
  {code: "287", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie thoracique et en pneumologie", "nl" : "", "en": ""}},
  {code: "310", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie des vaisseaux", "nl" : "", "en": ""}},
  {code: "340", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique", "nl" : "", "en": ""}},
  {code: "341", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique et en anatomie-pathologique", "nl" : "", "en": ""}},
  {code: "348", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique et en génétique clinique", "nl" : "", "en": ""}},
  {code: "349", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique et en urologie", "nl" : "", "en": ""}},
  {code: "367", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique et en biologie clinique", "nl" : "", "en": ""}},
  {code: "369", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gynécologie-obstétrique et en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "370", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie", "nl" : "", "en": ""}},
  {code: "371", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie avec médecine générale", "nl" : "", "en": ""}},
  {code: "374", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "378", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie et en oto-rhino-laryngologie", "nl" : "", "en": ""}},
  {code: "397", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie et en biologie clinique", "nl" : "", "en": ""}},
  {code: "398", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en ophtalmologie et en génétique clinique", "nl" : "", "en": ""}},
  {code: "410", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en oto-rhino-laryngologie", "nl" : "", "en": ""}},
  {code: "414", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en oto-rhino-laryngologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "422", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en oto-rhino-laryngologie et en stomatologie", "nl" : "", "en": ""}},
  {code: "450", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en urologie", "nl" : "", "en": ""}},
  {code: "454", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en urologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "455", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en urologie et en dermato-vénérologie", "nl" : "", "en": ""}},
  {code: "459", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en urologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "480", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique", "nl" : "", "en": ""}},
  {code: "481", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique, porteur du titre en médecine générale", "nl" : "", "en": ""}},
  {code: "489", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "493", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique et en rhumatologie", "nl" : "", "en": ""}},
  {code: "494", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "495", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique et en médecine physique et en réadaptation avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "496", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en chirurgie orthopédique et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "520", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en stomatologie", "nl" : "", "en": ""}},
  {code: "521", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en stomatologie avec médecine générale", "nl" : "", "en": ""}},
  {code: "550", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en dermato-vénérologie", "nl" : "", "en": ""}},
  {code: "566", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en dermato-vénérologie et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "567", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en dermato-vénérologie et en biologie clinique", "nl" : "", "en": ""}},
  {code: "569", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en dermato-vénérologie et en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "573", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et porteur des titres professionnels particuliers en gériatrie et en endocrinologie-diabétologie", "nl" : "", "en": ""}},
  {code: "580", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne", "nl" : "", "en": ""}},
  {code: "582", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne ayant une compétence particulière en médecine nucléaire in vitro", "nl" : "", "en": ""}},
  {code: "583", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne, porteur du titre professionnel particulier en endocrino-diabétologie", "nl" : "", "en": ""}},
  {code: "584", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "585", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en rhumatologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "586", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en génétique clinique", "nl" : "", "en": ""}},
  {code: "587", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en pneumologie", "nl" : "", "en": ""}},
  {code: "588", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en gastro-entérologie", "nl" : "", "en": ""}},
  {code: "589", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "591", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en cardiologie", "nl" : "", "en": ""}},
  {code: "592", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "593", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en rhumatologie", "nl" : "", "en": ""}},
  {code: "594", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste médecine interne + biologie clinique + médecine nucléaire", "nl" : "", "en": ""}},
  {code: "595", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie et en médecine interne avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "597", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en biologie clinique", "nl" : "", "en": ""}},
  {code: "598", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne, porteur du titre professionnel particulier en hématologie clinique", "nl" : "", "en": ""}},
  {code: "599", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine interne et en génétique clinique, porteur de titre professionnel particulier en hématologie clinique", "nl" : "", "en": ""}},
  {code: "620", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie", "nl" : "", "en": ""}},
  {code: "623", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie porteur de la qualification professionnelle particulièreen oncologie", "nl" : "", "en": ""}},
  {code: "624", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapé", "nl" : "", "en": ""}},
  {code: "628", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "629", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie et en pédiatrie", "nl" : "", "en": ""}},
  {code: "631", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie et en cardiologie", "nl" : "", "en": ""}},
  {code: "638", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pneumologie et en radiodiagnostic", "nl" : "", "en": ""}},
  {code: "650", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gastro-entérologie", "nl" : "", "en": ""}},
  {code: "651", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gastro-entérologie avec médecine générale", "nl" : "", "en": ""}},
  {code: "653", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gastro-entérologie porteur de la qualification professionnelle particulière en oncologie", "nl" : "", "en": ""}},
  {code: "659", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en gastro-entérologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "660", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en oncologie médicale", "nl" : "", "en": ""}},
  {code: "668", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en oncologie médicale et en génétique clinique", "nl" : "", "en": ""}},
  {code: "689", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neuropsychiatrie et en pédiatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "690", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie", "nl" : "", "en": ""}},
  {code: "691", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie porteur du titre professionnel particulier en neurologie pédiatrique", "nl" : "", "en": ""}},
  {code: "692", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie et en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "693", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie et en génétique clinique", "nl" : "", "en": ""}},
  {code: "694", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "695", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie et médecine physique et en réadaptation avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "696", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie porteur du titre professionnel particulier en neurologie pédiatrique et porteur du titre professionnel particulier en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "697", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie et en biologie clinique", "nl" : "", "en": ""}},
  {code: "698", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie, porteur du titre professionnel particulier en hématologie et oncologie pédiatriques", "nl" : "", "en": ""}},
  {code: "699", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en pédiatrie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "730", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie", "nl" : "", "en": ""}},
  {code: "731", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie avec médecine générale", "nl" : "", "en": ""}},
  {code: "733", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie et en rhumatologie", "nl" : "", "en": ""}},
  {code: "734", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "735", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie et en rhumatologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "736", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "737", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie et en génétique clinique", "nl" : "", "en": ""}},
  {code: "738", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie, porteur du titre professionnel particulier en médecine d’urgence et avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "739", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en cardiologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "760", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neuropsychiatrie", "nl" : "", "en": ""}},
  {code: "764", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neuropsychiatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "770", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurologie", "nl" : "", "en": ""}},
  {code: "774", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "779", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en neurologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "780", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en psychiatrie", "nl" : "", "en": ""}},
  {code: "784", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en psychiatrie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "788", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en psychiatrie et en génétique clinique", "nl" : "", "en": ""}},
  {code: "790", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie", "nl" : "", "en": ""}},
  {code: "793", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie et en médecine nucléaire avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "794", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "795", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation et en rhumatologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "796", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "798", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie et en génétique clinique", "nl" : "", "en": ""}},
  {code: "799", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en rhumatologie, porteur du titre professionnel particulier en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "800", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine aigüe", "nl" : "", "en": ""}},
  {code: "810", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en génétique clinique", "nl" : "", "en": ""}},
  {code: "811", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en génétique clinique avec médecine générale", "nl" : "", "en": ""}},
  {code: "830", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation,", "nl" : "", "en": ""}},
  {code: "831", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation avec médecine générale", "nl" : "", "en": ""}},
  {code: "834", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "835", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation et en radiothérapieoncologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "836", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation et en radiodiagnostic avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "838", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation et en radiodiagnostic", "nl" : "", "en": ""}},
  {code: "839", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine physique et en réadaptation et en radiothérapieoncologie", "nl" : "", "en": ""}},
  {code: "860", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique", "nl" : "", "en": ""}},
  {code: "861", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique avec médecine générale", "nl" : "", "en": ""}},
  {code: "862", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique ayant une compétence particulière en médecine nucléaire in vitro", "nl" : "", "en": ""}},
  {code: "866", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique et en anatomie-pathologique ayant une compétence particulière en médecine nucléaire in vitro", "nl" : "", "en": ""}},
  {code: "867", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique et en anatomie-pathologique", "nl" : "", "en": ""}},
  {code: "868", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en biologie clinique et en génétique clinique", "nl" : "", "en": ""}},
  {code: "870", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anatomie-pathologique", "nl" : "", "en": ""}},
  {code: "875", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en anatomie-pathologique et en dermato-vénérologie", "nl" : "", "en": ""}},
  {code: "900", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine d’urgence", "nl" : "", "en": ""}},
  {code: "930", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en radiodiagnostic", "nl" : "", "en": ""}},
  {code: "939", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en radiodiagnostic et en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "960", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "964", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en radiothérapie-oncologie avec reconnaissance en réadaptation- fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "965", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en radiothérapie-oncologie avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "970", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire", "nl" : "", "en": ""}},
  {code: "972", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en chirurgie", "nl" : "", "en": ""}},
  {code: "978", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en gynécologie-obstétrique", "nl" : "", "en": ""}},
  {code: "983", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en médecine interne, porteur du titre professionnel particulier en endocrino-diabétologie", "nl" : "", "en": ""}},
  {code: "985", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en médecine interne", "nl" : "", "en": ""}},
  {code: "987", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en gastro-entérologie", "nl" : "", "en": ""}},
  {code: "988", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en pédiatrie", "nl" : "", "en": ""}},
  {code: "989", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en cardiologie", "nl" : "", "en": ""}},
  {code: "991", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en rhumatologie", "nl" : "", "en": ""}},
  {code: "992", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en médecine physique et en réadaptation", "nl" : "", "en": ""}},
  {code: "994", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en radiodiagnostic", "nl" : "", "en": ""}},
  {code: "995", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en radiothérapie-oncologie", "nl" : "", "en": ""}},
  {code: "996", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire et en médecine interne avec reconnaissance en réadaptation fonctionnelle et professionnelle des handicapés", "nl" : "", "en": ""}},
  {code: "997", type : [PhysicianTypeEnum.SPECIALIST], speciality: {"fr": "médecin spécialiste en médecine nucléaire + radiodiagnostic + radiothérapie-oncologie", "nl" : "", "en": ""}}
];

export const nurseSpecialities: Array<string> = [
  '401',
  '402',
  '406',
  '407',
  '408',
  '411',
  '417',
  '418',
  '421',
  '422',
  '426',
  '428',
  '431',
  '438',
  '441',
  '442',
  '446',
  '448',
  '451',
  '458',
  '461',
  '462',
  '466',
  '468',
  '471',
  '478',
  '541',
  '542',
  '548',
  '551',
  '558',
  '561',
  '562',
  '568',
  '571',
  '578',
];
export const midwifeSpecialities: Array<string> = ['002', '003'];

export function isDoctor(nihii: string): boolean {
  return !!nihii && nihii?.length === 11 && nihii?.startsWith('1');
}

export function isSpecialist(nihii: string): boolean {
  return !!nihii && nihii?.length === 11 && Number(nihii?.substring(8)) >= 10 && nihii?.startsWith('1');
}

export function isNurse(nihii: string): boolean {
  const speciality: string = nihii && nihii.substring(8, 11);
  return (
    !!nihii &&
    nihii?.length === 11 &&
    !!nihii?.startsWith('4') &&
    !!nurseSpecialities.find((spec: string) => spec === speciality)
  );
}

export function isMidwife(nihii: string): boolean {
  const speciality: string = nihii && nihii.substring(8, 11);
  return (
    !!nihii &&
    nihii?.length === 11 &&
    !!nihii?.startsWith('4') &&
    !!midwifeSpecialities.find((spec: string) => spec === speciality)
  );
}

export function isSpeechTherapist(nihii: string): boolean{
  return (
    !!nihii &&
    nihii?.length === 11 &&
    !!nihii?.startsWith('58') || !!nihii?.startsWith('60')
  );
}

export function isPhysiotherapist(nihii: string): boolean {
  return !!nihii && nihii?.length === 11 && !!nihii?.startsWith('5');
}
