<div class="private-home">
  <mat-toolbar color="primary">
    <span>
      <img src="../../../assets/img/logo-medispring-vertical.svg" width="40px" height="40px" />
      Medispring Cockpit
      <span class="version" *ngIf="isFreeVersion"><i>(Version Free)</i></span>
      <span class="version" *ngIf="!isFreeVersion"><i>(Version Full)</i></span>
    </span>
    <span class="spacer"></span>
    <div fxFlex fxLayoutAlign="flex-end center">
      <!--
      <button mat-icon-button (click)="updateUser()">
        <mat-icon>cloud_upload</mat-icon>
      </button>-->
      <button mat-icon-button (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <mat-sidenav-container>
    <mat-sidenav mode="side" opened>
      <ms-side-menu [userInfos]="userInfos"></ms-side-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
